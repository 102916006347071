


.archive {
	background:#fff;
}





// BANNER TOP

$page_wrapper-bannerTop-desktop: 482px;
$page_wrapper-bannerTop-laptop: 460px;
$page_wrapper-bannerTop-tablet: 482px;
$page_wrapper-bannerTop-mobile: 236px;



.archive .banner.top {
    background:$footer-color;
    transition: height 1s;
    height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { height:$page_wrapper-bannerTop-mobile; }
}
.archive .banner.top .bloc-container {
    transition: line-height 1.8s;
    line-height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { line-height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { line-height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { line-height:$page_wrapper-bannerTop-mobile; }
}






.archive .banner.top {
	// padding-top:50px;
	// animation: fade 1s;
	transition:height 1s;
	.banner-content {
		@include brk($t) {
			padding:0;
		}
	}
	.content {
		height:100%;
		position: relative;
	}
}






.archive .banner.top .focus-container {
	background: #fff;
	width:50%;
	position: absolute;
	bottom:-40px;
	right:0;
	height:350px;
	z-index: 100;
	background:darken(#fff, 1%);
	box-shadow: 0px 5px 39px 0px rgba(0, 0, 0, 0.08);
	@include brk($td) {
		width:65%;
	}
	@include brk($m) {
		width:90%;
		height:199px;
		bottom:-40px;
	}

	.inside {
		padding:40px 70px;
		@include brk($m) {
			padding:30px 30px;
		}
		.focus {
			font-size: 9.5px;
			font-weight: $semi-bold;
			color:$touche1;
			animation: fade 1s;
			text-transform: uppercase;
			letter-spacing: 1.5px;
			margin-bottom: 40px;
			@include brk($m) {
				margin-bottom: 12px;
			}
		}
		.titre {
			font-size: 25px;
			font-weight: $bold;
			color:$touche2;
			animation: fade 1s;
			text-transform: uppercase; 
			line-height: 1.25;
			position: relative;
			&:before {
	            content:"";
	            display: inline-block;
	            background:$touche1;
	            width:20px;
	            height:1px;
	            position: absolute;
	            left:-40px;
	            top:22px;
	            @include brk($m) {
					display: none;
				}
	        }
			@include brk($m) {
				font-size: 17.5px;
			}
		}
		.lire-la-suite {
			position: absolute;
			bottom:75px;
			left:70px;

			@include brk($m) {
				left:30px;
				bottom:25px;
			}
			p {
				animation: fade 1s;
				color:$touche2;
				font-size:13px;
				font-weight: $bold;
			}
			&::after {
				content:"";
				display: inline-block;
				height:2px;
				width:0;
				background:$touche1;
				position: absolute;
				bottom:0;
				left:0;
				opacity: 0;
				transition: width 0.1s, opacity 0.8s;
			}
		}
		&::after {
			content:"";
			display: inline-block;
			width:1000%;
			height:100%;
			position: absolute;
			left:100%;
			top:0;
			background:$touche1;
			box-shadow: 0px 5px 39px 0px rgba(0, 0, 0, 0.08);
		}
	}
	&:hover {
		background:darken(#fff, 0%);
		.lire-la-suite {
			&::after {
				opacity: 1;
				width:93%;
			}
		}
	}
	
}











.archive .banner.medium {
	margin-top:-5px;
	background:#f4f4f4;
	padding:75px 0 190px;
	// margin-top:-5px;
	@include brk($t) {
		padding:80px 0 90px;
	}
	@include brk($m) {
		padding:85px 0 50px;
	}
	.fond {
        background-image:url('../assets/images/background-home.jpg');
        background-size: cover;
        background-position:50% 0%;
        position: absolute;
        top:0;
        left:0;
        right:0;
        height: 100%;
        width: 100%;
        animation: fade 1s;
        @include brk($m) {
            display: none;
        }
    }
	.content {
		position: relative;
	}
	.titre-type {
		font-size: 32.5px;
        font-weight: $bold;
        font-family: $family2;
        color: $touche2;
        text-transform: uppercase;
        letter-spacing: 4px;
        margin-bottom: 45px;
        @include brk($m) {
            font-size: 27.5px;
        }

        
        // TRAIT ------------------------
        position: relative;
        &::after {
            content:"";
            position: absolute;
            top:28px;
            left:-83px;
            height:1px;
            width:54px;
            opacity: 1;
            transition: width 0.1s, opacity 0.5s;
            display: inline-block;
            background: transparentize(#000, 0.6);
            @include brk($o) {
                display:none;
            }
        }
	}
}







.banner.list {
    background:darken(#fbfafa, 2%);
    padding:70px 0 80px;
    z-index:80;
	@include brk($t) { padding:60px 6% 60px; }
	@include brk($t) { padding:60px 0% 60px; }
	@include brk($m) { padding:50px 6% 10px; }
    .content {
        // position: relative;
    }
    .titre-container {
        margin-bottom: 47px;
        position: relative;
        .sur-titre {
            font-size: 11px;
            font-weight: $normal;
            color: $touche1;
            text-transform: uppercase;
            letter-spacing: 5px;
            margin-bottom: 10px;
        }
        .titre-type {
            font-size: 32.5px;
	        font-weight: $bold;
	        font-family: $family2;
	        color: $touche2;
	        text-transform: uppercase;
	        letter-spacing: 4px;
	        margin-bottom: 45px;
	        @include brk($m) {
	            font-size: 27.5px;
	        }

	        
	        // TRAIT ------------------------
	        position: relative;
	        &::after {
	            content:"";
	            position: absolute;
	            top:28px;
	            left:-83px;
	            height:1px;
	            width:54px;
	            opacity: 1;
	            transition: width 0.1s, opacity 0.5s;
	            display: inline-block;
	            background: transparentize(#000, 0.6);
	            @include brk($o) {
	                display:none;
	            }
	        }
        }
    }
}
















// LISTE TYPE 2 ----------------------------------------------


$li_height: 98px;



.archive .banner.medium,
.banner.list {
    ul {
		li {
			height:$li_height;
			width:100%;
			background:darken(#fff, 1%);
			margin-bottom: 1px;
			box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.03);
			@include brk($t) {
				width:100%;
				float: none!important;
			}
			@include brk($td) {
				background:transparentize(#fff, 0);
			}
			@include brk($m) {
				height:auto;
				margin-bottom: 27px;
				background:transparentize(#fff, 1);
			}
			.image-container {
				width:194px;
				position: relative;
				height:100%;
				float: left;
				// transition: filter 0.3s;
				filter: brightness(98%);
				@include brk($td) {
					filter: brightness(100%);
				}
				@include brk($m) {
					height:137px;
					width:100%;
					float: none!important;
				}
				.image {
					animation: none;
					background-size: cover;
				}
			}
			.textes-container {
				width:calc(100% - 194px);
				height:$li_height;
				float: right;
				position: relative;
				@include brk($t) {
					float: none;
					display: inline-block;
				}
				@include brk($tp) {
					padding: 5px 20px;
				}
				@include brk($m) {
					width:100%;
					height:auto;
					float: none!important;
					padding: 5px 0px 10px;
				}
				.infos-container {
					height:$li_height;
					line-height: $li_height;
					display: inline-block;
					padding-left:35px;
					width: 226px;
					height:$li_height;
					line-height: $li_height;
					@include brk($tp) {
						width: 100%;
						height:auto;
						line-height: 1;
						padding-left:0px;
					}
					@include brk($m) {
					}
					.inside {
						height:auto;
						@extend .bloc;
						border-right: 1px solid transparentize(#000, 0.9);
						@include brk($tp) {
							border-right: none;
						}
						p {
							font-size: 13px;
							font-weight: $semi-bold;
							display: inline-block;
							color:$touche1;
							letter-spacing: 0px;
							text-transform: uppercase;
							padding: 10px 0px;
							@include brk($tp) {
								font-size: 11px;
								padding: 10px 0px 7px;
							}
							@include brk($m) {
								font-size: 12px;
								border-right: none;
							}
							span {
								margin-left: 2px;
								margin-right: 7px;
							}
						}
					}
				}
				.titre-container {
					height:$li_height;
					line-height: $li_height;
					display: inline-block;
					width:calc(100% - 236px);
					@include brk($tp) {
						width: 100%;
						height:auto;
						line-height: 1;
					}
					@include brk($m) {
					}
					.titre-post {
						font-size: 16.5px;
						font-weight: $semi-bold;
						color:$touche2;
						padding: 0px 35px 0 25px;
						line-height: 1.35!important;
						@extend .bloc;
						@include brk($td) {
							font-size: 16px;
							line-height: 1.30!important;
						}
						@include brk($tp) {
							padding: 0px 0px;
							font-size: 15.5px;
						}
						@include brk($m) {
							
						}
					}
				}
				.lire-la-suite {
					height:56px;
					width: 56px;
					border-radius:50%;
					color:#fff;
					text-align: center;
					font-size:40px;
					font-weight: $normal;
					position: absolute;
					top:23px;
					right:23px;
					background: #ebebeb;
					.icon {
				        width:100%;
				        height:100%;
				        transition: transform 0.5s, background 0s;
				        transform: rotate(-90deg);
				        svg {
				            width:20px;
				            height:20px;
				            display: inline-block;
				            margin-top: 0px;
				            margin-right: 0px;
				            // transform: translate3d(5px,0,0);
				            .st0{fill:#FFFFFF;}
				        }
				    }
				    @include brk($t) {
						display: none;
					}
				}
			}
			&:hover {
				.image-container {
					filter: brightness(100%);
				}
				.lire-la-suite {
					background:#b3b3b3
				}
			}
			@include brk($d) {
				&:hover {
					background:#fff;
				}
			}
		}
	}
}





.single {
	background:#fbfafa;
}




// PAGE BANNER TOP

$page_wrapper-bannerTop-desktop: 400px;
$page_wrapper-bannerTop-laptop: 360px;
$page_wrapper-bannerTop-tablet: 219px;
$page_wrapper-bannerTop-mobile: 219px;



.single .banner.top {
    background:$footer-color;
    transition: height 1s;
    height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { height:$page_wrapper-bannerTop-mobile; }
}
.single .banner.top .bloc-container {
    transition: line-height 1.8s;
    line-height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { line-height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { line-height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { line-height:$page_wrapper-bannerTop-mobile; }
}





.single .banner.top {
	transition:height 1s;
    .image {
        background-position:50% 0%;
        background-image:url('../assets/images/2600x800_actus.jpg')
    }
	.banner-content {
		@include brk($t) {
			padding:0;
		}
	}
	.bloc {
		padding-bottom:60px;
	}
	.titre {
        font-size:45px;
        font-weight: $semi-bold;
		text-align: left;
        animation: fade 2s;
        line-height: 1.1;
        letter-spacing: 0.5px;
        color:#00528a;
        position: relative;
        z-index: 4;
        user-select:none;
        @include brk($t) { font-size: 40px; }
        @include brk($m) { font-size: 30px; }
    }
}








.single .banner.top .sub-banner{
	background:transparentize(#fbfafa, 0.4);
	position: absolute;
	bottom:0;
	left:0;
	right:0;
	.content {
		position: relative;
		height:83px;
		.btn-retour {
			height:100%;
			width: 185px;
			background: $touche2;
			display: inline-block;
			float: left;
			cursor:pointer;
			position: relative;
			animation: fade 1.2s;
			@extend .cache-left;
			.icon {
				position: absolute;
				height:14px;
				width:14px;
				left:32px;
				top:37px;
				transform: rotate(180deg);
					transition: transform 0.3s;
				.st0 {
					fill:#fff;
				}
				@include brk($m) {
					left:1%;
				}
			}
			p {
				font-size: 10px;
				font-weight: $bold;
				color:#fff;
				text-transform: uppercase;
				padding-left: 81px;
				line-height: 1;
				padding-top: 38px;
				letter-spacing: 1.5px;
				@include brk($m) {
					padding-left: 40px;
				}
			}
			@include brk($m) {
				width: 40px;
				p {display:none;}
			}
			&:hover {
				background: $touche2;
			}
		}
		.bloc-cat {
			height:100%;
			min-width: 160px;
			float: left;
			margin-left: 50px;
			padding:31px 48px 0 30px;
			position: relative;
			display: none;
			@include brk($m) {
				display: none;
			}
			.icon {
				animation: fade 1s;
				width:13px;
				height:13px;
				display:inline-block;
				position: absolute;
				margin-top: 2px;
				left:1px;
				top:33px;
				path {
					fill:$touche2;
				}
			}
			p {
				color:$touche2;
				user-select:none;
				font-size: 10px;
				animation: fade 1s;
	            text-transform: uppercase;
	            font-weight: $bold;
	            padding:5px 0 15px;
	            letter-spacing: 1px;
	            // transition: color 1s;
			}
		}
		.bloc-date {
			height:100%;
			min-width: 130px;
			float: left;
			// border-left: 1px solid transparentize(#000, 0.9);
			padding:31px 0px 0 66px;
			position: relative;
			@include brk($m) {
				border-left: none;
				// display: none;
			}
			.icon {
				animation: fade 1s;
				width:16px;
				height:16px;
				display:inline-block;
				position: absolute;
				left:30px;
				top:36px;
				path {
					fill:#7d7d7d;
					fill:$touche2;
				}
			}
			p {
				font-size: 10px;
	            font-weight: $bold;
				color:#7d7d7d;
				color:$touche2;
				text-align: right;
				user-select:none;
				animation: fade 1s;
	            text-transform: uppercase;
	            padding:5px 0 15px;
	            letter-spacing: 1px;
	            // transition: color 1s;
			}
		}
		.bloc-auteur {
			height:100%;
			min-width: 130px;
			float: left;
			// border-left: 1px solid transparentize(#000, 0.9);
			padding:32px 0px 0 10px;
			position: relative;
			@include brk($m) {
				border-left: none;
				padding-right: 5%;
				// display: none;
			}
			p {
				font-size: 10px;
	            font-weight: $bold;
				color:#7d7d7d;
				color:$touche3;
				display: inline;
				text-align: right;
				user-select:none;
				animation: fade 1s;
	            text-transform: uppercase;
	            padding:5px 0 15px;
	            letter-spacing: 1px;
	            // transition: color 1s;
	            span {
	            	margin-right: 9px;
	            }
			}
		}
	}
}
























.single .banner.medium {
	background:#fff;
	margin-top:-4px;
	.top-image {
		display: none !important;
	}

	.infos-banner {
		min-height:500px;
		position: relative;
		padding: 70px 8% 75px 20%;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		@include brk($t) {
			padding: 65px 5% 65px;
		}
		@include brk($m) {
			padding: 40px 0% 45px;
		}
		.post-titre {
			font-size: 40px;
			color:#494949;
			font-weight: $bold;
			line-height: 1.25;
			padding-bottom:30px;
			animation: fade 1s;
			@include brk($t) {
				font-size: 30px;
				padding-bottom:30px;
			}
			@include brk($m) {
				font-size: 22px;
				// line-height: 1.6;
			}
		}
		.post-chapeau {
			margin-bottom: 35px;
			p {
				font-size: 17.5px;
				font-weight: $light;
				letter-spacing: 0.5px;
			}
		}
		.post-image, .flex-image {
			height:314px;
			position: relative;
			margin-bottom: 45px;
			@include brk($m) {
				height:228px;
			}
			@include brk($m) {
				height:110px;
				margin-bottom: 30px;
			}
			.image {
				animation: fade 1s;
			}
		}
		.post-texte, .flex-texte {
			margin-bottom: 50px;
			@include brk($t) { margin-bottom: 40px; }
			@include brk($m) { margin-bottom: 30px; }
			p, a, span {
			}
			h2, h3, h4 {
				color:$touche1;
				font-weight: $bold;
				padding-top:14px;
			}
			h2 {

			}
		}
	}
	.gradient-white {
		height:120px;
		width:100%;
		position: absolute;
		bottom:0;
		left:0;
		background: linear-gradient(to bottom, 
			transparentize(#fff, 1) 0%, 
			transparentize(#fff, 0.5) 20%, 
			transparentize(#fff, 0.2) 50%, 
			transparentize(#fff, 0) 90%,
			transparentize(#fff, 0) 100%
		);
		@include brk($t) {display:none}
	}
	.module-partage {
		display:inline-block;
		height: 200px;
		width:50px;
		animation: fade 2s;
		text-align:right;
		position: absolute;
		top:73px;
		left:82px;
		// position: fixed;
		// top: $header-height + 83px + 74px;
		// left: calc(50% - 488px);
		@include brk($t) {
			top:auto;
			left: auto;
			position: relative;
			width:250px;
			margin-top: 20px;
			text-align:left;
			height: 65px;
		}
		@include brk($t) {
			margin-top: 0px;
		}
		.icon {
			height: 46px;
			width: 46px;
			margin-bottom: 18px;
			border:1px solid rgba(0,0,0,0.15);
			cursor: pointer;
			// background:rgba(0,0,0,0.15);
			border-radius:50%;
			@include brk($t) {
				margin-right: 13px;
			}
			&:hover {
				transition: border 0.3s;
				background:$touche1;
				border:1px solid $touche1;
				svg {
					// transform:scale(1.15);
				}
				.st0 {fill:#fff;}
			}
			svg {transform:scale(1.0); transition: transform 0.8s}
			.st0 {
				// transition: fill 1s;
				fill:$touche1;
			}
		}
	}
}





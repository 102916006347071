


.flexible {
    li:first-child .flex-titre {
        // margin-top: 0!important;
    }
    li:last-child .flex {
        // margin-bottom: 0;
    }
    .banner-content {
        // padding:0;
        // max-width: none;
    }
}



.flex {
    display: inline-block;
    position: relative;
    width:100%;
    .sur-titre {
        font-size: 10px;
        font-weight: $bold;
        color:$touche1;
        text-transform: uppercase;
        letter-spacing: 2.5px;
        line-height: 1.25;
        margin-bottom: 20px;
    }
    .titre {
        font-size: 22.5px;
        line-height: 1;
        font-weight: $bold;
        letter-spacing: 1px;
        color:$touche2;
        padding-bottom:18px;
        @include brk ($tp) { font-size: 21px; }
        @include brk ($m) { font-size: 20px; }
    }
    .chapeau p {
        font-size: 18px;
        font-weight: $normal;
        // font-style: italic;
        font-family: $family1;
        line-height: 1.5;
        color:#625538;
        margin-bottom: 20px;
        position: relative;
        clear: both;
        // padding-left:20px;
        // &:before {
        //     content:"";
        //     display: inline-block;
        //     background:#625538;
        //     width:14px;
        //     height:1px;
        //     position: absolute;
        //     left:0px;
        //     top:20px;
        // }
        @include brk($m) {
            font-size: 18px;
            padding-left:0px;
            margin-bottom: 10px;
            &:before {display:none}
        }

    }
    .lire-la-suite {
        margin-top: 20px;
        color:#fff;
        border-radius: 10px;
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 2.5px;
        font-weight: $bold;
        padding:20px 30px;
    }
}








.flex-titre-chapeau {
    margin-top: 0px;    
    margin-bottom: 25px;       
    .titre p {
        font-size: 45px;
        font-weight: $bold;
        line-height: 1.2;
        color:$touche2;
        padding-bottom:25px;
        border-bottom: 1px solid transparentize(#000, 0.9);
        margin-bottom: 5px;
        @include brk($t) {
            font-size: 40px;
        }
        @include brk($m) {
            font-size: 21px;
        }
    }
    .chapeau p {
        font-size: 22.5px;
        font-weight: $light;
    }
}

.flex-texte {margin-bottom: 35px;}

.flex-wysiwyg {
    margin-bottom: 40px;
    ul li {
        color:$touche1;
        font-weight: $bold;
        &:before {
            color:$touche1;
        }
    }
}


.flex-intro {
    background:#faf9f9;
    padding:60px 0 30px;
    margin-bottom: 65px;
    .texte {
        p, a, li {
            font-size: 21px;
            color: #010101;
        }
    }
}


.flex-focus {
    background:transparentize($touche1, 0.965);
    position: relative;
    padding: 30px 55px 30px 47px;
    margin-bottom:40px;
    @include brk($t) {
        padding: 35px 45px 10px 45px;
    }
    @include brk($m) {
        padding: 30px 35px 10px 35px;
        width:108%;
    }
    .titre {
        font-size: 27px;
        font-weight: $bold;
        font-family: $family1;
        padding-bottom:10px;
        color:$touche3;
        line-height: 1.2;
        letter-spacing: 1px;
    }
    .sous-titre {
        font-weight: $bold;
    }
    &:before {
        content:"";
        display: inline-block;
        height:100%;
        width:6px;
        position: absolute;
        left:0; top:0;
        background:$touche1;
    }
    ul {
        width: 100%;
        padding: 0 0 0 18px;
        li {
            font-size: 15px;
            font-weight: $normal;
            letter-spacing: 1px;
            padding: 11px 0px;
            padding-right: 40px;
            margin-bottom: 0;
            border-top:1px solid transparentize(#000, 0.9);
            @include brk($m) {
                padding-right: 20px;
            }
            p {
                padding-bottom: 0;
            }
            &:first-child {border-top:none;}
            &, &:before {
                color: $touche1;
            }
            &:before {
                // display: none;
                top:10px;
                left:-12px;
                font-weight: inherit;
            }
        }
    }
}


.flex-citation {margin-bottom: 60px;}


.flex-image {
    height:337px;
    margin-bottom: 27px;
    @include brk ($t) { height:290px; }
    @include brk ($m) { height:150px; }
    &.has-lightbox .image {
        cursor:pointer;
    }
}









// IMAGE | TEXTE,  TEXTE | IMAGE --------------------------------

.flex-image_texte, .flex-texte_image {
    margin-top: -5px;
    background:#f5f5f5;
    @include brk ($t) {
        // margin-bottom:70px;
    }
    .content {
        padding-top:110px;
        padding-bottom: 80px;
    }
    .ombre {
        position: absolute;
        display: inline-block;
        bottom:-44px;
        left:0;
        right:0;
        height:40px;
        width:100%;
        background-position:50% 1%;
        background-size: contain;
        z-index: 100;
    }
    .col-image {
        height:382px;
        width:382px;
        overflow: hidden;
        margin-top: 8px;
        border-radius: 50%;
        box-shadow: 0px 3px 40px 0px rgba(0, 0, 0, 0.35);
        @include brk($t) {
            width:340px;
            height:340px;
        }
        &.contains-img {
            @include brk ($m) {
                height:auto;
            }
        }
        .image {
            // border-radius:6px;
            animation: fade 2s;
        }
        >img {
            width:100%;
            height:auto;
            border-radius:8px;
            @include brk ($tp) {
                width:300px;
                height:auto;
            }
            @include brk ($m) {
                // width:100%;
                // height:auto;
            }
        }
        .image-lightbox {
            img {
                width:100%;
                visibility: hidden;
                height:auto;
            }
            .overlay {
                opacity: 0;
                transition: opacity 1s;
                background: transparentize(#00908e, 0.3);
                .icon {
                    width:50px;
                    height:50px;
                    position: absolute;
                    left:calc(50% - 25px);
                    top:calc(50% - 25px);
                    .st0{fill:none;stroke:#fff;stroke-width:2;stroke-linecap:square;stroke-miterlimit:10;}
                }
                &:hover {
                    transition: opacity 0.2s;
                    opacity: 1;
                }
            }
        }
        .bandeau {
            background:$touche1;
            color:#fff;
            z-index: 10;
            position: absolute;
            top:86px;
            right:calc(-75% + 100px);
            width:150%;
            text-align: center;
            padding: 14px 0;
            font-size: 10px;
            text-transform: uppercase;
            font-weight: $bold;
            letter-spacing: 3px;
            transform: rotate(45deg);
            @include brk ($m) {
                top:75px;
            }
        }
    }
    .col-texte {
        width:56%;
        @include brk($t) {
            width:calc(100% - 400px);
        }
        .sur-titre {
            font-size: 11px;
            font-weight: $black;
            font-family: $family2;
            text-transform: uppercase;
            letter-spacing: 3.5px;
            color:$touche1;
            margin-bottom: 25px;
        }
        .titre {
            text-transform: uppercase;
            font-size: 35.5px;
            margin-bottom: 20px;
            @include brk ($td) {
                font-size: 30px;
            }
            @include brk ($m) {
                font-size: 25px;
            }
        }
        .btn { 
            display: inline-block;
            background:$touche1;
            border-radius:30px;
            height: 40px;
            position: relative;
            text-decoration: none;
            margin-bottom: 30px;
            p {
                padding:14px 77px 16px 26px;
                font-size: 14px;
                font-weight: $bold;
                letter-spacing: 0;
                color:$white;
                line-height: 1;
                text-shadow: 0px 2px 0px rgba(95, 107, 120, 0.2)!important;
            }
            .icon {
                font-size: 38px;
                color:#fff;
                width:47px;
                text-align: center;
                font-weight: $normal;
                height:100%;
                position: absolute;
                top:3px;
                line-height: 1;
                right:6px;
                border:none;
                &::before {
                    content:"";
                    height:25px;
                    top:4px;
                    width:1px;
                    background:transparentize(#fff, 0.8);
                    position: absolute;
                    display: inline-block;
                    left:0px;
                }
                span {
                    transition: transform 0.5s;
                    display: inline-block;
                    height:100%;
                    line-height: 1;
                    color:$white;
                    transform: translateY(-5px);
                    text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.34);
                }
            }

            &:hover {
                background:$touche3;
            }
        }
        img.icone {
            width:71px;
            height:auto;
            float: left;
            margin-right: 20px;
            transform: translate3d(0,-23px,0);
        }
        ul.liste {
            width:100%;
            position: relative;
            &::before {
                content: "";
                display: inline-block;
                background: #f5f5f5;
                height: 5px;
                z-index: 10;
                width: 100%;
                position: absolute;
                top:8px;
                left:0;
                right:0;
            }

            li {
                width: calc(50% - 40px);
                display: inline-block;
                padding: 17px 0;
                margin:0;
                border-top: 1px solid transparentize(#625538, 0.85);
                margin-right: 40px;
                float: left;
                line-height: 1.6;
                @include brk ($t) {
                    width:100%;
                    float: none !important;
                }
                &:nth-child(2n) {
                    float: right;
                }
                &:before {display:none;}
                .caracteristique, .valeur {
                    display: inline;
                    padding:0;
                }
                .caracteristique {
                    font-weight: $bold;
                }
                p {
                    font-size: 16.5px;
                    line-height: 1.5;
                }
            }
        }
    }
    
    @include brk($tp) {
        .col-image, .col-texte {
            width:100%;
            float: none!important;
        }
        .col-image {
            height:280px;
            width: 280px;
            margin-bottom:40px;
            .image-lightbox img {
                margin: 0 auto;
                display: block;
                width:60%; 
            }
        }
    }
    @include brk ($m) { 
        margin-bottom:55px;
        .col-image {
            height:260px;
            width: 260px;
            margin-bottom:40px;
            .image {
                width:130%;
                left:-15%;
            }
        }
        ul.caracteristiques li {
            padding: 12px 0 !important;
            &:last-child {
                border-bottom:none;
            }
        }
    }
}

.flex-image_texte {
    .col-texte { float: right; }
}
.flex-texte_image {
    .col-image { float: right; }
}










// IMAGE LARGE | TEXTE

.flex-imagelarge_texte {
    padding-top:100px;
    padding-bottom: 80px;
    margin-top: -5px;
    background:#ffffff;
    @include brk ($t) {
        padding-top:90px;
    }
    @include brk ($td) {
        padding-top:80px;
    }
    @include brk ($tp) {
        padding-top:0px;
    }
    .col-image {
        height:450px;
        width:100%;
        overflow: hidden;
        margin-bottom: 80px;
        @include brk ($t) {
            height:350px;
        }
        @include brk ($td) {
            height:265px;
        }
        @include brk ($tp) {
            height:170px;
            overflow:visible;
        }
        &.contains-img {
            @include brk ($m) {
                height:auto;
            }
        }
        .image {
            // border-radius:6px;
            animation: fade 2s;
            @include brk ($tp) {
                width:114% !important;
                margin-left: -7%;
            }
        }
        >img {
            width:100%;
            height:auto;
            border-radius:8px;
            @include brk ($tp) {
                width:auto;
                height:100%;
            }
            @include brk ($m) {
                width:100%;
                height:auto;
            }
        }
        .image-lightbox {
            img {
                width:100%;
                visibility: hidden;
                height:auto;
            }
        }
        .bandeau {
            background:$touche1;
            color:#fff;
            z-index: 10;
            position: absolute;
            top:86px;
            right:calc(-75% + 100px);
            width:150%;
            text-align: center;
            padding: 14px 0;
            font-size: 10px;
            text-transform: uppercase;
            font-weight: $bold;
            letter-spacing: 3px;
            transform: rotate(45deg);
            @include brk ($m) {
                top:75px;
            }
        }
    }
    .col-texte {
        width:100%;
        .col-titres {
            width:40%;
            float: left;
            display: inline-block;
            @include brk ($tp) {
                width:100%;
                float: none;
            }
            .sur-titre {
                font-size: 11px;
                font-weight: $bold;
                font-family: $family2;
                text-transform: uppercase;
                letter-spacing: 3.5px;
                color:$touche1;
                margin-bottom: 30px;
            }
            .titre {
                text-transform: uppercase;
                font-size: 35.5px;
                font-weight: $light;
                b, span, strong {
                    font-weight: $bold;
                }
                // TRAIT ------------------------
                position: relative;
                &::after {
                    content:"";
                    position: absolute;
                    top:16px;
                    left:-83px;
                    height:1px;
                    width:54px;
                    opacity: 1;
                    transition: width 0.1s, opacity 0.5s;
                    display: inline-block;
                    background: transparentize(#496363, 0.7);
                    @include brk($t) {
                        display:none;
                    }
                }
            }
            img.icone {
                width:71px;
                height:auto;
                float: left;
                margin-right: 20px;
                transform: translate3d(0,-23px,0);
            }
        }
        .col-texte-inner {
            width:60%;
            float: right;
            display: inline-block;
            @include brk ($tp) {
                width:100%;
                float: none;
            }
        }
        ul.liste {
            width:100%;
            li {
                padding: 17px 0;
                margin:0;
                border-bottom: 1px solid transparentize(#625538, 0.85);
                &:before {display:none;}
                .caracteristique, .valeur {
                    display: inline;
                    padding:0;
                }
                .caracteristique {
                    font-weight: $bold;
                }
            }
        }
    }
    @include brk ($t) {
        margin-bottom:70px;
    }
    @include brk($tp) {
        .col-image, .col-texte {
            width:100%;
            float: none!important;
        }
        .col-image {
            height:275px;
            margin-bottom:40px;
            .image-lightbox img {
                margin: 0 auto;
                display: block;
                width:60%; 
            }
        }
    }
    @include brk ($m) { 
        margin-bottom:55px;
        .col-image {
            height:200px;
            margin-bottom:40px;
        }
        ul.caracteristiques li {
            padding: 12px 0 !important;
            &:last-child {
                border-bottom:none;
            }
        }
    }
}

.flex-image_texte {
    .col-texte { float: right; }
}
.flex-texte_image {
    .col-image { float: right; }
}





// APPEL A ACTION ----------------------------------------------



.flex-appel {
    text-align: center;
    // margin-bottom: 40px;
    .image-fond {
        background-image:url('../assets/images/fond-appel.png');
        background-position: 50% 50%;
        background-size:cover;
        @include brk($t) { opacity: 0.5 }
    }
    .banner-content {
        padding-top: 60px;
        padding-bottom: 65px;
        @include brk($t) { padding-top: 110px; }
        @include brk($m) { padding-top: 45px; }
    }
    .sur-titre {
        font-size: 11px;
        font-weight: $bold;
        font-family: $family2;
        text-transform: uppercase;
        letter-spacing: 3.5px;
        margin-bottom: 15px;
        color:$touche1;
        @include brk($m) { display:none }
    }
    .appel-titre {
        font-size: 35.5px;
        font-family: $family1;
        font-weight: $light;
        color:$touche2!important;
        position: relative;
        margin-bottom: 40px;
        line-height: 1.4;
        letter-spacing: 1px;
        b, strong, span {
            font-weight: $bold
        }
        @include brk($t) { font-size: 32px; }
        @include brk($m) { 
            font-size: 30px; 
            margin-bottom: 35px;
            span {display:block;}
        }
    }
    .btn { 
        background:$touche1;
        border-radius:30px;
        height: 40px;
        position: relative;
        p {
            padding:9px 77px 16px 26px;
            font-size: 14px;
            font-weight: $bold;
            letter-spacing: 0;
            color:$white;
            text-shadow: 0px 2px 0px rgba(95, 107, 120, 0.2)!important;
        }
        .icon {
            font-size: 38px;
            color:#fff;
            width:47px;
            text-align: center;
            font-weight: $normal;
            height:100%;
            position: absolute;
            top:3px;
            line-height: 1;
            right:6px;
            border:none;
            &::before {
                content:"";
                height:25px;
                top:4px;
                width:1px;
                background:transparentize(#fff, 0.8);
                position: absolute;
                display: inline-block;
                left:0px;
            }
            span {
                transition: transform 0.5s;
                display: inline-block;
                height:100%;
                line-height: 1;
                color:$white;
                transform: translateY(-5px);
                text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.34);
            }
        }

        &:hover {
            background:$touche3;
        }
    }
}




















// PARTENAIRES ----------------------------------------------


.flex-partenaires {
    background:#f7f6f5;
    padding:60px 0 80px;
    margin-bottom: 120px;
    @include brk($tp){
        padding:45px 0 30px;
        margin-bottom: 80px;
    }
    .titre-section {
        font-size: 25px;
        color:#625538;
        text-transform: uppercase;
        letter-spacing: 4px;
        margin-bottom: 10px;
        font-weight: $normal;
        @include brk($tp){
            text-align: center;
        }
    }
    .sous-titre {
        font-size: 17px;
        font-style: italic;
        color:#625538;
        font-weight: $regular;
        font-family: $family2;
        letter-spacing: 0;
        @include brk($tp){
            text-align: center;
        }
    }

    ul {
        padding-top: 80px;
        @include brk($tp){
            padding-top: 40px;
        }
        li {
            display: inline-block;
            width:33.33%;
            height:127px;
            margin-bottom: 30px;
            float: left;
            @include brk($t){
                width:50%;
            }
            @include brk($tp){
                width:100%;
                height:auto;
                margin-bottom: 35px;
            }
            .image-container {
                width:127px;
                height: 100%;
                float:left;
                background:#fff;
                .image {
                    width:80%;
                    height:80%;
                    top:10%;
                    left:10%;
                    background-size:contain;
                    filter:saturate(0%) sepia(10%);
                }
                @include brk($tp){
                    height:127px;
                    float: none;
                    display: block;
                    margin: 0 auto;
                    margin-bottom: 15px;
                }
            }
            &:hover {
                .image {
                    filter:saturate(100%) sepia(0%);
                }
            }
            .infos-container {
                padding-top:4px;
                width:calc(100% - 127px);
                float:right;
                padding-left:20px;
                @include brk($tp){
                    float: none;
                    margin: 0 auto;
                    text-align: center;
                    padding:0px;
                    width: 100%;
                }
                .entreprise {
                    font-size: 14px;
                    text-transform: uppercase;
                    font-weight: $bold;
                    color:#625538;
                    @include brk($tp){
                        display: inline-block;
                        width: 100%;
                    }
                }
                p, a {
                    font-size: 14px;
                    line-height: 1.70;
                    font-weight: $light;
                    color:#968d78;
                    display: block;
                    float:left;
                    clear: both;
                    @include brk($tp){
                        float: none;
                        display: inline-block;
                        width: 100%;
                    }
                }
                span {
                    @include brk($tp) {
                        display: inline-block;
                        width: 100%;
                    }
                }
                .courriel, .site {
                    position: relative;
                    display: inline-block;
                    @include brk($tp) {
                        width: auto;
                    }
                    &::after {
                        content:"";
                        width:100%;
                        height:1px;
                        display: inline-block;
                        position: absolute;
                        bottom:3px;
                        left:0;
                        right:0;
                        background:transparentize(#968d78, 0.5);
                    }
                    &:hover::after {
                        height: 2px;
                        bottom:2px;
                        opacity: 1;
                        background:transparentize(#968d78, 0);
                    }
                }
                .site {
                    &::after {
                        opacity: 0;
                    }
                }
            }
        }
    }


}











.page.les-lieux .banner.carte {
	height:1150px;
	@include brk($td) {
		height:auto;
	}

	.texture {
		background-image:url('../assets/images/texture-map.jpg');
		background-size:cover;
		background-position:50% 50%;
		display: inline-block;
		height:100%;
		width: 100%;
		position: absolute;
		top:0;
		left:0;
		bottom:0;
		right:0;
	}
	.map {
		display: inline-block;
		height:100%;
		width: 100%;
		position: absolute;
		top:0;
		left:-200px;
		bottom:0;
		animation: fade 2s;
		@include brk($td) {
			top:-90px;
			height:700px;
			left:0px;
		}
		@include brk($m) {
			top:-90px;
			height:550px;
			left:-20%;
			width:140%;
		}
	}
	.boussole {
		position: absolute;
		display: inline-block;
		top:50px;
		left:6%;
		width:80px;
		height:80px;
		background:#fff;
		border-radius: 50%;
		overflow: hidden;
		box-shadow: 0px 3px 40px 0px rgba(0, 0, 0, 0.35);
		display: none;
		@include brk($td) {
			display: inline-block;
		}
		@include brk($m) {
			top:20px;
		}
		.st0{clip-path:url(#XMLID_14_);}
		.st1{clip-path:url(#XMLID_15_);fill:#FFFFFF;}
		.st2{clip-path:url(#XMLID_15_);}
		.st3{clip-path:url(#XMLID_17_);fill:#008F8D;}
		.st4{clip-path:url(#XMLID_19_);fill:#008F8D;}
		.st5{clip-path:url(#XMLID_15_);fill:#486363;}
	}



	.banner-content {
		padding-top:150px;
		@include brk($td) {
			padding-top:355px;
		}
		@include brk($m) {
			padding:245px 0 0;
		}
	}

	.cadran {
		width: 450px;
		min-height:740px;
		float: right;
		background:#fff;
		padding:50px 0px 50px;
		text-align: center;
		position: relative;
		animation: fade_scale_90 1s;
		box-shadow: 0px 3px 40px 0px rgba(0, 0, 0, 0.35);
		@include brk($td) {
			width:100%;
			min-height:500px;
			margin-bottom: 140px;
		}
		.sur-titre {
			font-size: 11px;
			font-family: $family2;
			text-transform: uppercase;
			color:#00908e;
			font-weight: $black;
			letter-spacing: 2px;
			margin-bottom: 5px;
			padding:0 50px;
		}
		.titre {
			font-size: 40px;
			text-transform: uppercase;
			color:#496363;
			padding:0 50px;
			font-weight: $light;
			margin-bottom: 15px;
		}
		.trait {
			background:$touche1;
			width:50px;
			height:4px;
			margin: 0 auto;
			margin-bottom: 20px;
		}
		.texte {
			padding:0 50px;
			display: inline-block;
			margin-bottom: 14px;
			p {
				font-size: 13.5px;
				font-style: italic;
				font-family: $family2;
				color:#727272;
				margin-bottom: 20px;
			}
		}
		.btn { 
	        background:$touche1;
	        border-radius:30px;
	        height: 40px;
	        width: 250px;
	        position: absolute;
	        left:calc(50% - 125px);
	        bottom:-20px;
	        p {
	            padding:9px 77px 16px 26px;
	            font-size: 14px;
	            font-weight: $bold;
	            letter-spacing: 0;
	            color:$white;
	            text-shadow: 0px 2px 0px rgba(95, 107, 120, 0.2)!important;
	        }
	        .icon {
	            font-size: 38px;
	            color:#fff;
	            width:47px;
	            text-align: center;
	            font-weight: $normal;
	            height:100%;
	            position: absolute;
	            top:3px;
	            line-height: 1;
	            right:6px;
	            border:none;
	            &::before {
	                content:"";
	                height:25px;
	                top:4px;
	                width:1px;
	                background:transparentize(#fff, 0.8);
	                position: absolute;
	                display: inline-block;
	                left:0px;
	            }
	            span {
	                transition: transform 0.5s;
	                display: inline-block;
	                height:100%;
	                line-height: 1;
	                color:$white;
	                transform: translateY(-5px);
	                text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.34);
	            }
	        }

	        &:hover {
	            background:$touche3;
	        }
	    }
	}

	nav#nav_lieux {
		
		>ul>li>a,
		>ul>li>ul {
			display: none;
		}
		>ul>li:nth-child(2)>ul {
			display: block;
		}

		>ul>li>ul {
			position: relative;
			text-align: center;
			@include brk($td) {
            	padding: 0 4%;
            }
			&::before {
                content: "";
                background: #fff;
                height: 5px;
                z-index: 10;
                width: 100%;
                position: absolute;
                top:-1px;
                left:0;
                right:0;
                display: none;
                @include brk($td) {
                	display: inline-block;
                }
            }

			a {
				padding: 23px 50px;
				display: inline-block;
				width: 100%;
				font-size: 12px;
				color:#80bb22;
				text-transform: uppercase;
				transition: border 0.5s;
				border-top:1px solid transparentize(#000, 0.9);
				&::before, &::after {
					content:"";
					width:10px;
					height:1.5px;
					background:transparentize(#80bb22, 0);
					display: inline-block;
					transform:translateY(-3px);
					margin: 0 8px;
				}
			}
			li {
				padding:0 22%;
				@include brk($td) {
					width:50%;
					padding:0 3%;
					float: left;
				}
				@include brk($m) {
					width:100%;
				}
				&:hover {
					background:transparentize($touche3, 0.9);
					&, & + li {
						a {
							transition: border 0.2s;
							border-top:1px solid transparentize(#000, 1);
						}
					}
					a {
						color:#00908e;
						&::before, &::after {
							background:transparentize(#00908e, 0);
						}
					}
				}
			}
			li:first-child {
				a {
					border:none;
				}
			}
		}
	}

}






@keyframes show_lieu {
	0% {
		opacity: 0;
		transform: scale(0.9);
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}



.map {
	#Chateau, #Cour, #Grange, #Parc, #Ecurie, #Fenil {
		display: none;
		// animation: fade 1s;
		&.visible {
			display: inline-block;
			animation: show_lieu 1s;
		}
	}
}




.map {
	.st0{opacity:0.5;fill:#BBCEA7;enable-background:new    ;}
	.st1{fill:#A8A8A7;}
	.st2{fill:#DADADA;}
	.st3{fill:url(#SVGID_1_);}
	.st4{fill:url(#SVGID_2_);}
	.st5{fill:url(#SVGID_3_);}
	.st6{fill:url(#SVGID_4_);}
	.st7{fill:#9D9D9C;}
	.st8{fill:#646363;}
	.st9{fill:#95A6B1;}
	.st10{fill:#DAE0E3;}
	.st11{fill:url(#SVGID_5_);}
	.st12{fill:url(#SVGID_6_);}
	.st13{fill:url(#SVGID_7_);}
	.st14{fill:url(#SVGID_8_);}
	.st15{fill:url(#SVGID_9_);}
	.st16{fill:url(#SVGID_10_);}
	.st17{fill:url(#SVGID_11_);}
	.st18{fill:url(#SVGID_12_);}
	.st19{fill:url(#SVGID_13_);}
	.st20{fill:url(#SVGID_14_);}
	.st21{fill:url(#SVGID_15_);}
	.st22{fill:url(#SVGID_16_);}
	.st23{fill:url(#SVGID_17_);}
	.st24{fill:url(#SVGID_18_);}
	.st25{fill:url(#SVGID_19_);}
	.st26{fill:url(#SVGID_20_);}
	.st27{fill:url(#SVGID_21_);}
	.st28{fill:url(#SVGID_22_);}
	.st29{fill:url(#SVGID_23_);}
	.st30{fill:url(#SVGID_24_);}
	.st31{fill:url(#SVGID_25_);}
	.st32{fill:url(#SVGID_26_);}
	.st33{fill:url(#SVGID_27_);}
	.st34{fill:url(#SVGID_28_);}
	.st35{fill:url(#SVGID_29_);}
	.st36{fill:url(#SVGID_30_);}
	.st37{fill:url(#SVGID_31_);}
	.st38{fill:url(#SVGID_32_);}
	.st39{fill:url(#SVGID_33_);}
	.st40{fill:url(#SVGID_34_);}
	.st41{fill:url(#SVGID_35_);}
	.st42{fill:url(#SVGID_36_);}
	.st43{fill:url(#SVGID_37_);}
	.st44{fill:url(#SVGID_38_);}
	.st45{fill:url(#SVGID_39_);}
	.st46{fill:url(#SVGID_40_);}
	.st47{fill:url(#SVGID_41_);}
	.st48{fill:url(#SVGID_42_);}
	.st49{fill:url(#SVGID_43_);}
	.st50{fill:url(#SVGID_44_);}
	.st51{fill:url(#SVGID_45_);}
	.st52{fill:url(#SVGID_46_);}
	.st53{fill:url(#SVGID_47_);}
	.st54{fill:url(#SVGID_48_);}
	.st55{fill:url(#SVGID_49_);}
	.st56{fill:url(#SVGID_50_);}
	.st57{fill:url(#SVGID_51_);}
	.st58{fill:url(#SVGID_52_);}
	.st59{fill:url(#SVGID_53_);}
	.st60{fill:url(#SVGID_54_);}
	.st61{fill:url(#SVGID_55_);}
	.st62{fill:url(#SVGID_56_);}
	.st63{fill:url(#SVGID_57_);}
	.st64{fill:url(#SVGID_58_);}
	.st65{fill:url(#SVGID_59_);}
	.st66{fill:url(#SVGID_60_);}
	.st67{fill:url(#SVGID_61_);}
	.st68{fill:url(#SVGID_62_);}
	.st69{fill:url(#SVGID_63_);}
	.st70{fill:url(#SVGID_64_);}
	.st71{fill:url(#SVGID_65_);}
	.st72{fill:url(#SVGID_66_);}
	.st73{fill:url(#SVGID_67_);}
	.st74{fill:url(#SVGID_68_);}
	.st75{fill:url(#SVGID_69_);}
	.st76{fill:url(#SVGID_70_);}
	.st77{fill:url(#SVGID_71_);}
	.st78{fill:url(#SVGID_72_);}
	.st79{fill:url(#SVGID_73_);}
	.st80{fill:url(#SVGID_74_);}
	.st81{fill:url(#SVGID_75_);}
	.st82{fill:url(#SVGID_76_);}
	.st83{fill:url(#SVGID_77_);}
	.st84{fill:url(#SVGID_78_);}
	.st85{fill:url(#SVGID_79_);}
	.st86{fill:url(#SVGID_80_);}
	.st87{fill:url(#SVGID_81_);}
	.st88{fill:url(#SVGID_82_);}
	.st89{fill:url(#SVGID_83_);}
	.st90{fill:url(#SVGID_84_);}
	.st91{fill:url(#SVGID_85_);}
	.st92{fill:url(#SVGID_86_);}
	.st93{fill:url(#SVGID_87_);}
	.st94{fill:url(#SVGID_88_);}
	.st95{fill:url(#SVGID_89_);}
	.st96{fill:url(#SVGID_90_);}
	.st97{fill:url(#SVGID_91_);}
	.st98{fill:url(#SVGID_92_);}
	.st99{fill:url(#SVGID_93_);}
	.st100{fill:url(#SVGID_94_);}
	.st101{fill:url(#SVGID_95_);}
	.st102{fill:url(#SVGID_96_);}
	.st103{fill:url(#SVGID_97_);}
	.st104{fill:url(#SVGID_98_);}
	.st105{fill:url(#SVGID_99_);}
	.st106{fill:url(#SVGID_100_);}
	.st107{fill:url(#SVGID_101_);}
	.st108{fill:url(#SVGID_102_);}
	.st109{fill:url(#SVGID_103_);}
	.st110{fill:url(#SVGID_104_);}
	.st111{fill:url(#SVGID_105_);}
	.st112{fill:url(#SVGID_106_);}
	.st113{fill:url(#SVGID_107_);}
	.st114{fill:url(#SVGID_108_);}
	.st115{fill:url(#SVGID_109_);}
	.st116{fill:url(#SVGID_110_);}
	.st117{fill:url(#SVGID_111_);}
	.st118{fill:url(#SVGID_112_);}
	.st119{fill:url(#SVGID_113_);}
	.st120{fill:url(#SVGID_114_);}
	.st121{fill:url(#SVGID_115_);}
	.st122{fill:url(#SVGID_116_);}
	.st123{fill:url(#SVGID_117_);}
	.st124{fill:url(#SVGID_118_);}
	.st125{fill:url(#SVGID_119_);}
	.st126{fill:url(#SVGID_120_);}
	.st127{fill:url(#SVGID_121_);}
	.st128{fill:url(#SVGID_122_);}
	.st129{fill:url(#SVGID_123_);}
	.st130{opacity:0.7;fill:#E1BE8C;enable-background:new    ;}
	.st131{fill:url(#SVGID_124_);}
	.st132{fill:url(#SVGID_125_);}
	.st133{fill:url(#SVGID_126_);}
	.st134{fill:url(#SVGID_127_);}
	.st135{fill:url(#SVGID_128_);}
	.st136{fill:url(#SVGID_129_);}
	.st137{fill:url(#SVGID_130_);}
	.st138{fill:url(#SVGID_131_);}
	.st139{fill:url(#SVGID_132_);}
	.st140{fill:#C6C6C6;}
	.st141{fill:#878787;}
	.st142{fill:#B2B2B2;}
	.st143{fill:#BDBCBC;}
	.st144{opacity:0.4;fill:#F1F1F1;enable-background:new    ;}
	.st145{opacity:0.5;fill:#8D6F55;enable-background:new    ;}
	.st146{fill:url(#SVGID_133_);}
	.st147{fill:url(#SVGID_134_);}
	.st148{fill:url(#SVGID_135_);}
	.st149{fill:url(#SVGID_136_);}
	.st150{fill:url(#SVGID_137_);}
	.st151{fill:url(#SVGID_138_);}
	.st152{fill:url(#SVGID_139_);}
	.st153{fill:url(#SVGID_140_);}
	.st154{fill:url(#SVGID_141_);}
	.st155{fill:url(#SVGID_142_);}
	.st156{fill:url(#SVGID_143_);}
	.st157{fill:url(#SVGID_144_);}
	.st158{fill:url(#SVGID_145_);}
	.st159{fill:url(#SVGID_146_);}
	.st160{fill:url(#SVGID_147_);}
	.st161{fill:url(#SVGID_148_);}
	.st162{fill:url(#SVGID_149_);}
	.st163{fill:url(#SVGID_150_);}
	.st164{fill:url(#SVGID_151_);}
	.st165{fill:url(#SVGID_152_);}
	.st166{fill:url(#SVGID_153_);}
	.st167{fill:url(#SVGID_154_);}
	.st168{fill:url(#SVGID_155_);}
	.st169{fill:url(#SVGID_156_);}
	.st170{fill:url(#SVGID_157_);}
	.st171{fill:url(#SVGID_158_);}
	.st172{fill:url(#SVGID_159_);}
	.st173{fill:url(#SVGID_160_);}
	.st174{fill:url(#SVGID_161_);}
	.st175{fill:url(#SVGID_162_);}
	.st176{fill:url(#SVGID_163_);}
	.st177{fill:url(#SVGID_164_);}
	.st178{fill:url(#SVGID_165_);}
	.st179{fill:url(#SVGID_166_);}
	.st180{fill:url(#SVGID_167_);}
	.st181{fill:url(#SVGID_168_);}
	.st182{fill:url(#SVGID_169_);}
	.st183{fill:url(#SVGID_170_);}
	.st184{fill:url(#SVGID_171_);}
	.st185{fill:url(#SVGID_172_);}
	.st186{fill:url(#SVGID_173_);}
	.st187{fill:url(#SVGID_174_);}
	.st188{fill:url(#SVGID_175_);}
	.st189{fill:url(#SVGID_176_);}
	.st190{fill:url(#SVGID_177_);}
	.st191{fill:url(#SVGID_178_);}
	.st192{fill:url(#SVGID_179_);}
	.st193{fill:url(#SVGID_180_);}
	.st194{fill:url(#SVGID_181_);}
	.st195{fill:url(#SVGID_182_);}
	.st196{fill:url(#SVGID_183_);}
	.st197{fill:url(#SVGID_184_);}
	.st198{fill:url(#SVGID_185_);}
	.st199{fill:url(#SVGID_186_);}
	.st200{fill:url(#SVGID_187_);}
	.st201{fill:url(#SVGID_188_);}
	.st202{fill:url(#SVGID_189_);}
	.st203{fill:url(#SVGID_190_);}
	.st204{fill:url(#SVGID_191_);}
	.st205{fill:url(#SVGID_192_);}
	.st206{fill:url(#SVGID_193_);}
	.st207{fill:url(#SVGID_194_);}
	.st208{fill:url(#SVGID_195_);}
	.st209{fill:url(#SVGID_196_);}
	.st210{fill:url(#SVGID_197_);}
	.st211{fill:url(#SVGID_198_);}
	.st212{fill:#E9E9E9;}
	.st213{fill:url(#SVGID_199_);}
	.st214{fill:url(#SVGID_200_);}
	.st215{fill:url(#SVGID_201_);}
	.st216{fill:url(#SVGID_202_);}
	.st217{fill:url(#SVGID_203_);}
	.st218{fill:url(#SVGID_204_);}
	.st219{fill:url(#SVGID_205_);}
	.st220{fill:url(#SVGID_206_);}
	.st221{fill:url(#SVGID_207_);}
	.st222{fill:url(#SVGID_208_);}
	.st223{fill:url(#SVGID_209_);}
	.st224{fill:url(#SVGID_210_);}
	.st225{fill:url(#SVGID_211_);}
	.st226{fill:url(#SVGID_212_);}
	.st227{fill:url(#SVGID_213_);}
	.st228{fill:url(#SVGID_214_);}
	.st229{fill:url(#SVGID_215_);}
	.st230{fill:url(#SVGID_216_);}
	.st231{fill:url(#SVGID_217_);}
	.st232{fill:url(#SVGID_218_);}
	.st233{fill:url(#SVGID_219_);}
	.st234{fill:url(#SVGID_220_);}
	.st235{fill:url(#SVGID_221_);}
	.st236{fill:url(#SVGID_222_);}
	.st237{fill:url(#SVGID_223_);}
	.st238{fill:#E3E3E3;}
	.st239{fill:#FFFFFF;}
	.st240{fill:#706F6F;}
	.st241{fill:#058F8D;}
	.st242{fill:none;stroke:#058F8D;stroke-width:10;stroke-miterlimit:10;}
	.st243{fill:none;stroke:#058F8D;stroke-width:8;stroke-miterlimit:10;}
	.st244{fill:none;stroke:#058F8D;stroke-width:7.4949;stroke-miterlimit:10;}
	.st245{fill:none;stroke:#058F8D;stroke-width:7.982;stroke-miterlimit:10;}
	.st246{fill:none;stroke:#FFFFFF;stroke-width:2.4972;stroke-miterlimit:10;}
}




#header_behind {
    height:$header-height;
    background: #fff;
    position: relative;
    @include brk($t) {height:$header-height-tablet;}
    @include brk($m) {height:$header-height-mobile;}
    &.page {
        background: #fff;
    }
}


#header {
    height:$header-height;
    position:fixed;
    top:0px;
    left:0px; 
    z-index: 99;
    background:linear-gradient(to bottom, 
        #ffffff 0%,
        // transparentize(#fff, 0.4) 50%,
        transparentize(#fff, 1) 100%
    );
    background:$white;
    border-bottom: 1px solid rgba(255,255,255,0.15);
    box-shadow: 0px 3px 40px 0px rgba(0, 0, 0, 0.35);
    @include brk($t) {height:$header-height-tablet;}
    @include brk($m) {height:$header-height-mobile;}
    &.page {
        background:none;
    }
    .background {
        position: absolute;
        left:0;
        top:0;
        width:100%;
        display: inline-block;
        height:$header-height;
        @include brk($t) {height:$header-height-tablet;}
        @include brk($m) {height:$header-height-mobile;}
    }
    .banner-content {
        height: $header-height;
        margin: 0 auto;
        @include brk($menu-large) {
            margin:0; 
            padding:0 40px;
            transition: 0.5s;
        }
        @include brk($t) {
            height:$header-height-tablet;
            padding:0 6%;
        }
        @include brk($m) {height:$header-height-mobile;}
    }
}







#header .logo-container {
    float: left;
    width: 206px;
    height: $header-height;
    position: relative;
    transition: box-shadow 0.3s;
    display: inline-block;
    @include brk($t) {
        height: $header-height-tablet;
        width: 150px;
    }
    @include brk($m) {
        height: $header-height-mobile;
        width: 150px;
    }
    .logo-image {
        height: 100%;
        animation: fade 2s;
        width: 100%;
        // background: url('../images/logo.png');
        // background: url('../images/logo.svg');
        background-size:contain;
        background-position:50% 50%;
        display: inline-block;
        position: absolute;
        top:0px;
        left:0px;
        @include brk($t) {
            top:-2px;
        }
        @include brk($m) {
        }
    }
}







#header .btn-01 {
    border-radius:30px;
    height: 40px;
    float: right;
    margin-top: 47px;
    margin-left: 27px!important;
    p {
        padding:9px 77px 16px 26px;
        font-size: 14px;
        font-weight: $normal;
        letter-spacing: 0;
        text-shadow: 0px 4px 0px rgba(95, 107, 120, 0.34)!important;
    }
    .icon {
        font-size: 38px;
        color:#fff;
        width:47px;
        text-align: center;
        font-weight: $normal;
        height:100%;
        position: absolute;
        top:3px;
        line-height: 1;
        right:6px;
        border:none;
        &::before {
            content:"";
            height:25px;
            top:4px;
            width:1px;
            background:transparentize(#fff, 0.8);
            position: absolute;
            display: inline-block;
            left:0px;
        }
        span {
            transition: transform 0.5s;
            display: inline-block;
            height:100%;
            line-height: 1;
            transform: translateY(-5px);
            text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.34);
        }
    }
    @include brk($t) {
        display: none;
    }
}














#nav_desktop {
    display:inline-block;
    height:100%;
    float:right;
    @include brk($t) {display:none;}
    >ul {
        list-style-type:none;
        height:100%;
        user-select: none;
    }
    >ul>li {
        display:inline-block;
        height:$header-height;
        line-height:$header-height;
        margin-left:-5px;
        user-select: none;
        position: relative;
        &:hover, &.current-menu-item, &.current_page_ancestor {
            >a {color:transparentize(#0b4a49,0);}
        }
    }
    >ul>li>a {
        display: block;
        padding: 58px 16px 20px;
        font-size: 12px;
        letter-spacing: 0.6px;
        color: transparentize(#0b4a49,0.1);
        font-weight: $bold;
        text-transform: uppercase;
        height:100%;
        user-select: none;
        &:hover {color:transparentize(#0b4a49,0);} 
    }



    // SOULIGNÉ ----------------------- 

    >ul>li:not(.btn)>a:before {
        content:"";
        width:0px;
        opacity: 0;
        transition: width 0.2s, opacity 0.5s, background 0.3s;
        height:3.5px;
        position: absolute;
        display: inline-block;
        background:#00908e;
        left:16px;
        bottom:25px;
    }
    >ul>li:not(.btn):hover >a:before,
    >ul>li:not(.btn).current-menu-ancestor >a:before,
    >ul>li:not(.btn).current-menu-parent >a:before,
    >ul>li:not(.btn).current-menu-item >a:before {
        opacity: 1;
        width:calc(100% - 32px);
    }

    >ul>li>ul {display:none;}
}














// WPML -----------------------------------------------------


#header.page .wpml-ls-legacy-dropdown {
    >ul>li>a {
        color:#fff !important;
        span::after {
            background: url('../assets/images/wpml_down-white.svg')
        }
    }
}


#header .wpml-ls-legacy-dropdown {
    height: $header-height;
    position: relative; 
    display: inline-block;
    float:right;
    @include breakpoint ($menu-off) {
        display: none;
    }
    >ul {
        height:$header-height;
    }
    a {
        font-size: 14px;
        font-weight: $bold;
        color: transparentize($touche2, 0.1);
        font-family: $family1;
        text-transform: uppercase;
    }
    >ul:hover >ul>li>a {
        color: transparentize($touche2, 0);
    }
    >ul>li>a {
        height:$header-height;
        padding:  53px 16px 10px 12px;
        span {
            position: relative;
            &::after {
                content:"";
                width:8px;
                height:8px;
                position:absolute;
                top:6px;
                right:-14px;
                background-size:contain!important;
                background: url('../assets/images/wpml_down.svg')
            }
        }
    }
    ul:hover, ul:hover ul {
        visibility:visible !important;
    }
    >ul>li>ul {
        visibility:hidden;
        position:absolute;
        // height:100%;
        top:$header-height - 8px;
        left:-12px;
        z-index: 100;
        padding: 0 20px 20px;
    }
    >ul>li>ul>li {
        height: 40px; 
        width: 40px;
        background:#eeeeee;
        border-radius:50%;
        margin-bottom: 9px;
        &:hover {background:$white;}
    }
    >ul>li>ul>li>a {
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.15);
        width: 100%;
        height:100%;
        text-align: center;
        padding-top:8px;
        border-radius:50%;
    }
}

@keyframes arrow_move_down {
    0% {bottom:1px;}
    100% {bottom:-9px;}
}

.page .banner.nav {
	// margin-top:-5px;
	display:block;
	position: relative;
	min-height:84px;
	transition: background 1s;
	background:#496363;
	z-index:10;
	@include brk($m) { margin-top:0px; }
	.couleur-overlay {
		@extend .abs;
	}

	nav#nav_child {
		position: relative;
		>ul>li>a, >ul>li:not(.current-menu-parent)>ul>li, >ul>li:not(.current-menu-parent)>ul {
	        display: none;
	    }


		// LEVEL 2  -----------------
		>ul>li {
			transition: background 2s;
			display:none;@extend .cache-left, .cache-right}
		>ul>li {
			display:block!important;
			animation: fade 1s;
			// >a {display:none;}
			>ul>li {
				display: inline-block;
				position: relative;
				float: left;
				@include brk($m) { 
					&, &>a {width:100%; }
					&:first-child a {padding-top:2px;}
					&:last-child a {padding-bottom:2px;}
				}
				&.active, &.current_page_item, &:hover {
					background:#3a4f4f !important;
					a::after { opacity: 1; }
				}
				a {
					height:84px;
					padding:34px 33px;
					color:#fff;
					font-size: 11px;
					font-weight: $bold;
					letter-spacing: 1.5px;
					text-transform: uppercase;
					&::after {
						content:"";
						display: inline-block;
						position: absolute;
						bottom:0px;
						left:0;
						height:9px;
						width:100%;
						opacity: 0;
						background: transparentize(#000, 0.5);
						@include brk($m) { display:none }
					}
					@include brk($m)  { 
						height: auto;
						border-top: 1px solid transparentize(#fff, 0.9);
						padding:18px $padding-mobile; 
					}
				}
			}
		}



		>ul>li>ul>li.active {
			// display:block!important;
			// animation: fade 2s;
			// >a {display:none;}


			// LEVEL 3  -----------------

			>ul>li {
				display: inline-block;
				position: relative;
				@include brk($m) { 
					&, &>a {width:100%; }
					&:first-child a {padding-top:2px;}
					&:last-child a {padding-bottom:2px;}
				}
				a::after {
					content:"";
					display: inline-block;
					position: absolute;
					bottom:-9px;
					left:0;
					height:9px;
					width:100%;
					opacity: 0;
					background: darken($touche1, 10%);
					@include brk($m) { display:none }
				}
				&.active, &:hover {
					background:$touche1 !important;
					a::after {
						transition: bottom 0.3s, opacity 0.4s;
						opacity: 1;
						bottom:0px;
					}
				}
				a {
					height:84px;
					padding:30px 30px;
					color:#fff;
					font-size: 14px;
					@include brk($m)  { 
						height: auto;
						border-top: 1px solid transparentize(#fff, 0.9);
						padding:18px $padding-mobile; 
					}
				}
			}
		}

	}
}

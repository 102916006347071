






.page.galerie {
    .banner.medium {
        background: #ffffff;
        min-height: 400px;
        padding: 120px 0 180px !important;
        >.banner-content {
            // padding:0;
            // max-width: none;
        }
    }
    .loader-circle, .loader-line-mask {
        top: calc(50% - 220px);
    }
}







//  FORMAT CLASS
// ______________________________________




$format__p__font_size: 		16.5px;
$format__p__font_size_m:	16px;
$format__p__font_weight: 	$light;
$format__p__color: 			darken(#496363, 0%);
$format__p__letter_spacing: 0px;
$format__p__word_spacing: 	0px;
$format__p__line_height: 	2;

$format__p__margin_top: 	0px;
$format__p__margin_bottom: 20px;

$format__a__font_size: 		16px;
$format__a__font_weight: 	$normal;
$format__a__color: 			$touche1;

$format__h__font_weight : 	$bold;
$format__h__font_family: 	$family1;
$format__h__color : 		#496363;
$format__h__letter_spacing: 1.5;
$format__h__text_transform: none;
$format__h__padding_top: 	0px;
$format__h__padding_bottom: 20px;


* {
	// font-family:  Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-family: $family1;
}

p, li, a, h1, h2, h3, h4, h5, h6 {
	color:$grey;
}
p {
	font-size: $format__p__font_size;
	font-weight: $format__p__font_weight;
	line-height: $format__p__line_height;
	color: $format__p__color;
	letter-spacing: $format__p__letter_spacing;
	word-spacing: $format__p__word_spacing;
	margin:0;
	margin-top: $format__p__margin_top;
	margin-bottom: $format__p__margin_bottom;
	@include brk($m) {
		font-size: $format__p__font_size_m;
		text-align: left!important;
		line-height: $format__p__line_height - 0.1;
	}
	span {
		-webkit-text-stroke: $format__p__color;
	}
}
blockquote {
	padding:40px 1% 30px;
	p {
		font-size:$format__p__font_size;
		font-weight:$semi-bold;
		color:$format__a__color;
		text-align:left;
		// font-style: italic;
		line-height:1.8;
	}
}
a {
	font-size: $format__a__font_size;
	font-weight: $format__a__font_weight;
	color: $format__a__color;
	line-height: $format__p__line_height;
	letter-spacing: $format__p__letter_spacing;
	word-spacing: $format__p__word_spacing;
	-webkit-text-stroke: $format__a__color;
	text-decoration: underline;
	// font-style:italic;
	margin-left: 2px;
	margin-right: 2px;
	position: relative;
	// &::after {
	// 	content:"";
	// 	display: inline-block;
	// 	position: absolute;
	// 	height:1px;
	// 	left:0;
	// 	bottom:3px;
	// 	width:0;
	// 	opacity: 0;
	// 	background: $format__a__color;
	// 	transition: width 0.1s, opacity 1s;
	// }
	// &:hover::after {
	// 	width:100%;
	// 	opacity:0.6;
	// }
	// @include brk($td) {
	// 	&::after {
	// 		width:100%;
	// 		opacity:0.4;
	// 	}
	// }
	@include brk($m) {
		font-size: $format__p__font_size_m;
		text-align: left!important;
		line-height: $format__p__line_height - 0.1;
	}
}


h2, h3, h4 {
	font-weight: $format__h__font_weight;
	font-family: $format__h__font_family;
	color:$format__h__color;
	line-height:1.6;
	padding-top:$format__h__padding_top;
	padding-bottom:$format__h__padding_bottom;
	letter-spacing: $format__h__letter_spacing;
	text-transform: $format__h__text_transform;
	a {
		font-size:inherit;
	}
}
li {
	font-size: $format__p__font_size;
	font-weight: $format__p__font_weight;
	color: $format__p__color;
	line-height:1.9;
	list-style-type:none;
	// display: table-row;
	margin-bottom:10px;
	vertical-align: top;
	padding-left:15px;
	position: relative;
	letter-spacing: $format__p__letter_spacing;
	word-spacing: $format__p__word_spacing;
	// &::after {content:"";display:table-row;height:10px;}
}
ul, ol {
	padding-top:10px;
	padding-bottom:20px;
	display:table;
}
ul {
    li:before {
        content: '›';
        color: $format__p__color;
        font-weight: $bold;
        position: absolute;
		left: 0px;
		top: 0px;
    }
}
ol {
    // list-style-position: inside;
    margin-left:20px;
    counter-reset: foo;
    li {
        counter-increment: foo;
        padding-left:17px;
        &::before {
        	content: counter(foo) ".";
        	position: absolute;
			left: 2px;
			top: 0px;
        }
    }
}



.alignleft {
	float: left;
	margin: 5px 40px 20px 0;
}
.alignright {
	float: right;
	margin: 5px 0 20px 40px;
}
.aligncenter {
	margin: 20px auto 15px;
	display: block;
}


/* NAV TABLET
====================*/




// LEVEL 1
$bg-l1: #fff;
$bg-l1-top: #f8f8f8;
$bg-l1-robe: darken($touche2, 0%);


// LEVEL 2
$bg-l2: darken($touche2, 0%);
$bg-l2-top: darken($touche2, 10%);
$bg-l2-robe: darken($touche2, 10%);


// LEVEL 3
$bg-l3: darken(#4cacb2, 6%);
$bg-l3-top: $dark-red;
$bg-l3-robe: darken($touche2, 10%);







@import "hamburger/00_hamburger";
#hamburger_container {
    position: fixed;
    background: $touche1;
    animation: fade 1s;
    width:$header-height; 
    height:$header-height;
    z-index: 1010;
    right:0;
    top:0;
    margin-right: - ($header-height + 50px);
    &.active {
        // animation:none!important
    }
    @include brk($t) {
        display:inline-block;
        margin-right: 0;
        width:$header-height-tablet; 
        height:$header-height-tablet;
    }
    @include brk($m) {
        width:$header-height-mobile; 
        height:$header-height-mobile;
    }
    // button {
    //     &:before {
    //         content:"";
    //         @extend .abs;
    //         background:url('../assets/images/sandwish-gradient.png');
    //         height:80%;
    //         background-size:cover;
    //         background-position:0% 0%;
    //         opacity: 1;
    //     }
    // }
}







// CONTAINER ------------------------------------

@keyframes open_menu {
    0% { transform:translate3d(100%, 0px, 0px); }
    100% { transform:translate3d(0, 0px, 0px); }
}


#nav_mobile_container {
    transform:translate3d(0px, 0px, 0px);
    transition: transform 0.3s, opacity 0.3s;
    z-index: 990;
    height:100%;
    top:0px;
    right:-1px;
    overflow: hidden;
    position:fixed;
    display: none;
    user-select:none;
    &.opened {
        display: block;
        animation: open_menu 0.3s;
    }
    @include brk ($t) {width:320px;}
    @include brk ($m) {max-width:calc(100% - 68px);}
}




// LEVEL 1 --------------------------------------------

#nav_mobile {
    height:100%;
    overflow-x: hidden;
    overflow-y: scroll;
    width: calc(100% + 15px);
    background:$bg-l1-robe;
    >ul {
        margin-top:$header-height;
        @include brk($t) {margin-top:$header-height-tablet;}
        @include brk($m) {margin-top:$header-height-mobile;}
    }
    >ul img {display:none;}
    >ul li:first-child {
        padding-top:5px; 
        a {border:none!important;}
    }
    >ul li a {
        font-size:11px;
        font-weight: $bold;
        line-height: 1.5;
        position:relative;
        padding: 20px 0 20px 30px;
        letter-spacing: 1.5px;
        animation: fade 1.5s;
        text-transform: uppercase;
        border-top:1px solid transparentize(#000,0.9);
        transition: border 0.6s;
    }
    >ul>li {
        background:$bg-l1;
        padding-right:15px;
    }
    >ul>li {
        &:hover, &.current-menu-item, &.current-menu-ancestor, &.current-menu-parent {
            background:darken($bg-l1, 6%);
            >a, + li>a {border-top:1px solid transparentize(#000,1);}
        }
    }
    >ul>li>a {
        display: inline-block;
        position: relative;
        width:100%;
        color:#032e2d;
    }


    // ICON FLECHE ENFANTS --------------------------------

    >ul li.menu-item-has-children>a::after {
            content:"›";
            position: absolute;
            font-size:18px;
            font-weight: $normal;
            height:100%;
            box-sizing: inherit;
            color:$touche1;
            width:40px; 
            top:0px;
            right:0px;
            padding-left:25px;
            padding-top: 14px;
            // background:#edecec;
            // text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.15);
            @include brk($m) {}
        }



    // ICON EXIT ------------------------------------------
    
    >ul>li.menu-item-object-custom>a[target="_blank"] {
        &::after {
            content:"››";
            position: absolute;
            letter-spacing: 0;
            font-size:28px;
            font-weight: $light;
            height:100%;
            box-sizing: inherit;
            color:#aaa;
            width:62px; 
            top:-5px;
            right:0px;
            padding-left:25px;
            padding-top: 4px;
        }
    }
}






// LEVEL 2 --------------------------------------------


#nav_mobile >ul>li {
    ul {
        z-index: 1012;
        top:$header-height;
        @include brk($t) {top:$header-height-tablet;}
        @include brk($m) {top:$header-height-mobile;}
        li>a {
            color:#eee;
            display: block;
            opacity: 0.1;
            transition: opacity 0.35s;
        }
        &.opened {
            transform: translate3d(7.4%, 0px, 0px);
            >li>a {
                opacity: 1;
            }
        }
    }
    >ul>li {
        background:$bg-l2;
    }
    >ul {
        background:$bg-l2-robe;
        overflow-y: scroll;
        overflow-x: hidden;
        height:100%;
        width: 108.1%;
        position:absolute;
        right:1px;
        transform:translate3d(108%, 0px, 0px);
        transition: transform 0.3s;
    }
    >ul>li {
        &.current-menu-item, &:hover {
            background:darken($bg-l2, 6%);
            >a, + li>a {border-top:1px solid transparentize(#fff,1);}
        }
    }


    // ICON FLECHE ENFANTS --------------------------------
    >ul li.menu-item-has-children>a::after {
        color:#fff;
        right:40px;
    }
}













// TOP LINE NAMES

.bar-top {
    display: block;
    height:$header-height-tablet;
    line-height: $header-height-tablet;
    position:absolute;
    transform:translate3d(0px, 0px, 0px);
    transition: transform 0.3s;
    top:0px;
    right:0px;
    width:100%;
    padding-right:$header-height-tablet;
    p {
        font-size:9px;
        color:$white;
        padding-left: 30px;
        text-transform: uppercase;
        letter-spacing: 2px;
        display: inline-block;
        vertical-align: middle;
        line-height: 1.5!important;
    }
    &::after {content:none;}
    &.l1 { background:$bg-l1-top;z-index: 1011; }
    &.l2 { background:$bg-l2-top;z-index: 1013; }
    &.l3 { background:$bg-l3-top;z-index: 1015; }
    &.l2, &.l3 {
        transform:translate3d(100%, 0px, 0px);
        &.opened { transform: translate3d(0px, 0px, 0px) }
    }
    @include brk($m) {
        height:$header-height-mobile;
        line-height: $header-height-mobile;
        padding-right:$header-height-mobile;
    }
}









#calling_button {
    height:$header-height-mobile;
    width:$header-height-mobile;
    // background: $touche2;
    position:fixed;
    right:$header-height-mobile;
    top:0;
    z-index: 100;
    animation: fade 1s;
    display: none;
    .icon {
        position: absolute;
        width:45%;
        height:45%;
        background:$touche3;
        top:27%;
        left:23%;
        border-radius:50%;
    }
    svg {
        transform: scale(0.35);
        path {fill: #fff}
    }
    &:hover {
        // background:darken($touche2, 5%);
    }
    &.page {
        // background: #fff;
        svg path {fill:#222d62;}
        &:hover {
            // background:darken(#fff, 5%);
        }
    }
    @include brk($t) {display: inline-block;}
    @include brk($m) {
        // left:0;
    }
}










// SEARCH ICON ----------------------------------


#nav_mobile .icon-search-container {
    position: absolute;
    top:0;
    left:0;
    height:$header-height-tablet;
    width:$header-height-tablet;
    background:#06a0c3;
    z-index: 1012;
    .icon {
        margin-top:5px;
        width: 40px;
        height: 40px;
        transition: transform 0.5s, background 0.6s;
        svg {transform:scale(0.55)}
        path {fill:#fff;}
    }
}













// WPML -----------------------------------------------------


.wpml-ls-statics-footer.wpml-ls-legacy-list-horizontal {
    position: absolute;
    // height:100%;
    padding-left:12px;
    overflow: hidden;
    z-index: 1012;
    animation: fade 2s;
    @extend .bar-top;
    >ul>li>a {
        font-size: 11.5px;
        font-weight: $bold;
        font-family: $family1;
        color:#fff;
        padding: 26px 22px;
        display: inline-block;
        height:100%;
        text-transform: uppercase;
    }
    >ul>li {
        display: inline-block;
        position: relative;
        &:before {
            content: "";
            background:transparentize(#2e2b26, 0.5);
            height:15px;
            width:1px;
            display: inline-block;
            position: absolute;
            left:0;
            top:28px;
        }
        &:first-child:before {
            display: none;
        }
    }
}



/* ================================================================================
    VARIABLES  
==================================================================================*/


//  COLORS  -------------------------------------------------


$main-background-color: #f1f1f1;
$footer-color: #0e1517;

$touche1: #00908d;
$touche2: #496363;
$touche3: #80bb22;
$touche4: #d8b463;

$couleur1: $touche1;
$couleur2: $touche2;
$couleur3: #7c7b87;
$couleur4: #d8b463;
$couleur5: #909f5a;




//  FONTS  ------------------------------------------------------

@import "../base/fontface";
// @import url(http://fonts.googleapis.com/css?family=Anonymous+Pro); 

$family1 : 'Montserrat', sans-serif;
$family2 : 'Playfair Display', serif;

$color_default : #333;


$thin: 100;
$light: 300;
$normal: 400;
$regular: $normal;
$semi-bold: 600;
$bold: 700;
$black: 900;



div, time, div, a, p, input, li, h1, h2, h3, h4, h5, h6 {
    font-family: $family1;
    color:$color_default;
}


a, p, h1, h2, h3, h4, h5, h6 {
    line-height:1.65; 
    font-weight:400;
    letter-spacing: 0.3px;
    word-spacing: 1px;
}

p, h1, h2, h3, h4, h5, h6 {user-select:text}
a, p {font-size:15px}
h1 {font-size:30px}
h2 {font-size:24px}
h3 {font-size:20px}
h4 {font-size:16px}
h5 {font-size:15px}
h6 {font-size:14px}




//  HEADER HEIGHTS  -------------------------------------------

$header-height:116px;
$header-height-tablet:68px;
$header-height-mobile:68px;




//  WIDTHS ---------------------------------------------------

$width-left: 36.5%;
$width-right: 63.5%;

$bannerContent-maxWidth-extra: 1300px;
$bannerContent-maxWidth-extra: calc(30% + 650px);
$bannerContent-maxWidth-desktop: 1300px;
$extra: min-width 2200px;    




//  PADDINGS ---------------------------------------------------

$padding-desktop: 70px;
$padding-tablet: 6%;
$padding-mobile: 6%;




//  BREAKPOINTS  ---------------------------------------------


$small-mobile: 567px;
$mobile: 639px;
$table-portrait: 768px;
$tablet-device: 1024px;
$tablet-device-and-up: min-width 1025px;
$tablet: 1226px;
$menu-large: 1270px;
$old-desktop: 1280px;
$laptop: 1367px;



$desktop: min-width 1025px;         // for more than 1024px width
$large-desktop: max-width 1200px;   // for less than 1200px width
$small-desktop: max-width 1440px;
$nav-tablet: max-width 1130px;
$menu-off: 1070px;
$mobile-hi-res: 767px (min-resolution 1.5dppx);  // mobile retina



$d:   $desktop;
$l:   $laptop;
$o:   $old-desktop;
$t:   $tablet;
$tdu: $tablet-device-and-up;
$td:  $tablet-device;
$tp:  $table-portrait;
$m:   $mobile;
$sm:  $small-mobile;








// BUTTON WIDTHS
    $button-width-tablet: $header-height-tablet;
    $button-width-mobile: $header-height-mobile;



// COLORS
    // White
        $light-white: rgba(255,255,255,0.89);
        $white:rgba(255,255,255,1);
    // Grey
        $light-grey: #e2e2e2;
        $grey: #56565a;
        $dark-grey: #2d2d2d;
    // Colors
        $yellow:#E8D761;
        $orange:#f39b1e;
        $vert:#92cdc3;
        $brown:#71645B;
        $bleu:#416681;
        $red:#11eeee;
        $dark-red:#118888;
        $light-brown:#B2A47F;
        $rose:#f1999f;
        $mauve:#896a90;


// MENU COLORS
    $level2: #333333;
    $level3: $light-grey;


// SPEEDS
    $speed1: 0.6s;







// SOCIAL COLORS --------------------------------

$twitter: #00aced;
$facebook: #3b5998;
$googleplus: #dd4b39;
$pinterest: #cb2027;
$linkedin: #007bb6;
$youtube: #bb0000;
$vimeo: #aad450;
$tumblr: #32506d;
$instagram: #517fa4;
$flickr: #ff0084;
$dribbble: #ea4c89;
$quora: #a82400;
$foursquare: #0072b1;
$forrst: #5B9A68;
$vk: #45668e;
$wordpress: #21759b;
$stumbleupon: #EB4823;
$yahoo: #7B0099;
$blogger: #fb8f3d;
$soundcloud: #ff3a00;


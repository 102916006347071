


.page.contact {
    background:#fff;
}



// PAGE BANNER TOP

$page_wrapper-bannerTop-desktop: 450px;
$page_wrapper-bannerTop-laptop: 320px;
$page_wrapper-bannerTop-tablet: 393px;
$page_wrapper-bannerTop-mobile: 393px;

.page.contact .banner.top {
	transition: height 1s;
	height:$page_wrapper-bannerTop-desktop;
	@include brk($l) { height:$page_wrapper-bannerTop-laptop; }
	@include brk($t) { height:$page_wrapper-bannerTop-tablet; }
	@include brk($m) { height:$page_wrapper-bannerTop-mobile; }
}
.page.contact .banner.top .bloc-container {
	transition: line-height 1.8s;
	line-height:$page_wrapper-bannerTop-desktop;
	@include brk($l) { line-height:$page_wrapper-bannerTop-laptop; }
	@include brk($t) { line-height:$page_wrapper-bannerTop-tablet; }
	@include brk($m) { line-height:$page_wrapper-bannerTop-mobile; }
}



.page.contact .banner.top {
	position: relative;
    background:#fff;
	.image {
		background-position: 50% 32% !important;
        background-size: cover !important;
	}
	.bloc {
		@include brk($t) {
			padding: 25px 0 0 0;
		}
	}
	.sur-titre {
		animation: fade 2.5s;
		font-size: 19px;
		color:$touche1;
		letter-spacing: 0.5px;
		font-weight: $light;
		position: relative;
		z-index: 4;
        margin-bottom:6px;
		@include brk($t) { 
			animation: fade_80 2.5s;
			opacity:0.8;
			// color:#fff;
		}
		@include brk($m) { font-size: 18px; }
	}
	.titre {
		animation: fade 2s;
		font-size:32.5px;
		font-weight: $bold;
        font-family: $family2;
		letter-spacing: 0.5px;
		color:#555868;
        line-height: 1.35;
		position: relative;
		z-index: 4;
		&:before {
            content:"";
            display: inline-block;
            height:3px;
            width:20px;
            position: absolute;
            left:-40px; top:27px;
            background:$touche1;
            @include breakpoint ($t) {
                display: none;
            }
        }
		@include brk($t) { font-size: 35px; }
		@include brk($m) { font-size: 25px; }
	}
	#google_map {
        animation: fade 2.5s;
        height:100%;
        width:100%;
        // height:390px;
        position: absolute!important;
        /*======= Map Styling ============*/
        .gmnoprint a, .gmnoprint span {
            display:none;
        }
    }
    .gradient {
        position: absolute;
        height:180px;
        top:auto;
        bottom:0;
        left:0;
        right:0;
        z-index: 900;
        background: linear-gradient(to bottom, 
            transparentize(#fff, 1), 
            transparentize(#fff, 0.7) 30%, 
            transparentize(#fff, 0.4) 50%, 
            transparentize(#fff, 0.1) 80%, 
            transparentize(#fff, 0)
        );
    }
}










.page.contact .banner.top {
	.image { 
		animation: fade 1s; 
		background-position: 50% 20%;
	}
}



.page.contact .banner.medium {
	overflow:visible;
    // background:#f7f7f7;
	padding-top:0;
	animation:none;
    margin-top: -5px;
	.banner-content {
		@include brk($t) { padding:0 }
		.content {
			position: relative;
		}
	}
}




















// COLONNES ------------------------------------

.page.contact {
    .col-side {
        width:30%;
        float: left;
        background:#fff;
        animation: fade 2s;
        @extend .cache-left;
        @extend .cache-bottom-left;
        .inside {
            display: inline-block;
            padding: 80px 0% 80px 0px;
            text-align: left;
            animation: fade 1s;
            @include brk($t) { 
                padding: 50px 0% 40px 0;
            }
            @include brk($m) { 
                text-align: left; 
                padding: 40px 0% 30px 0;
            }
        }
    }
    .col-main {
        width:70%;
        float: right;
        background:$contact_form_background;
        padding: 70px 0px 70px 130px;
        animation: fade 1.5s;
        @extend .cache-right;
        @extend .cache-bottom-right;
        @include brk($t) { 
            padding: 20px 6% 50px;
            width: 100%; }
        @include brk($m) {
            padding: 40px 6% 0px;
            padding-left:$padding-mobile;
            padding-right:$padding-mobile;
            width: 100%;
        }
    }
}









.page.contact .col-side {
    min-height: 300px;
    z-index: 21;
    @include brk($t) { 
        width: 100%; 
        padding-left:6%;
        padding-right:6%;
        &::after {display:none;}
    }
    @include brk($m) {  
        width: 100%;  
        padding-left:$padding-mobile;
        padding-right:$padding-mobile;
    }
    .col-temp {
        @include brk($t) {
            width:60%;
            // float: left;
        }
        @include brk($tp) {
            width:100%;
            float: none;
        }
    }
    .logo-container {
        width: 190px;
        margin-bottom: 60px;
        margin-left: -3px;
        .st0{clip-path:url(#SVGID_2_);fill:#7BC142;}
        .st1{clip-path:url(#SVGID_2_);fill:#009D96;}
    }
    .titre-colonne {
        font-size: 13.5px;
        font-weight: $normal;
        font-family: $family2;
        color:#4f4630;
        position: relative;
        margin-bottom: 5px;
        text-transform: uppercase;
        letter-spacing: 3px;
        @include brk($t) {
            display: none;
        }
    }
    .sur-titre {
        font-size: 12px;
        color:#8f8f8f;
        font-weight: $light;
        letter-spacing: 2px;
        text-transform: uppercase;
        margin-bottom: 5px;
    }
    .nom-entreprise {
        font-size: 22.5px;
        color:$touche1;
        font-weight: $bold;
        line-height: 2;
        span {
            font-weight: $light;
            color:#4a4337;
        }
    }
    .sous-titre {
        font-size: 20px;
        color:$touche3;
        font-weight: $light;
        letter-spacing: 0px;
        margin-bottom: 30px;
    }
    .titre-section {
        font-size: 17.5px;
        font-weight: $bold;
        color:$touche1;
        padding-top: 8px;
        margin-bottom: 10px;
    }
    .coordonnees-container {
        padding-bottom:8px;
        span {
            font-size: 17px;
            font-weight: $bold;
            font-family: $family2;
            display:block;
            color:#4f4630;
            &[itemprop="address"] p, &[itemprop="address"] h2 { 
                display: block; 
                margin-left:0px;
            }
            &.contact1 {
                padding-top: 5px;
                margin-bottom: 10px;
                @include brk($t){
                    margin-bottom: 0px;
                }
                @include brk($m){
                    margin-bottom: 5px;
                }
            }
            h2, p, a {
                font-size: 17px;
                font-weight: $regular;
                font-family: $family2;
                color:#4f4630;
                line-height: 1.8;
                display: inline;
                margin-left:5px;
                &.nom-entreprise {
                    font-weight: $bold;
                    padding-top:15px;
                }
                &.pays {
                    text-transform: uppercase;
                    padding-bottom:10px;
                    @include brk($t) {
                        padding-bottom: 30px;
                    }
                }

            }
            p {
                line-height: 1.8;
                letter-spacing: 0px;
                display: block;
                @include brk($t){
                    display: inline-block;
                }
                @include brk($m){
                    display: block;
                }
            }
            a {
                position: relative;
                &::after {
                    content:"";
                    display: inline-block;
                    position: absolute;
                    bottom:1px;
                    left:0;
                    height:2px;
                    width:0;
                    opacity: 0;
                    transition: opacity 0.5s, width 0.2s;
                    background: lighten($touche1, 0%);
                }
                &:hover {
                    &::after {
                        width:100%;
                        opacity: 1;
                    }
                }
            }
        }
    }
    .petit-trait {
        height: 2.5px;
        background:$touche1;
        margin-top: 17px;
        margin-bottom:18px;
        width:21px;
    }
    .trait {
        height: 13px;
        border-bottom:1px solid transparentize(#000, 0.9);
        margin-bottom:16px;
        width:80%;
        &.top-email {
            margin-top: 8px;
        }
        @include brk($t) {  display: none }
    }
    .container {
        float: none;
        clear: both;
        display: inline-block;
        width: 100%;
        margin-top: 20px;
        &.secretariat {
            margin-top: 30px;
        }
        .titre-section {
            font-size: 16px;
            font-weight: $bold;
            color:$touche3;
            line-height: 1.5;
            text-transform: uppercase;
            padding-top: 8px;
            margin-bottom: 0px;
        }
        p {
            font-size: 20px;
            font-weight: $normal;
            line-height: 1.85;
            color:$touche3;
        }
    }
    .googlemap-container {
        display: inline-block;
        width: 100%;
        margin-top: 5px;
        margin-bottom: 20px;
        @include brk($t) { margin-top: 15px; }
        @include brk($m) { margin-top: 15px; }
        p {
            font-size: 17px;
            font-weight: $bold;
            font-family: $family2;
            float: left;
            // width:100px;
            margin-top: 13px;
            display: inline-block;
            margin-right: 15px;
            color:#4f4630;
            transition: color 1s;
            span {
                font-weight: $normal;
            }
            @include brk($m) { 
                float: left; 
            }
        }
        .icon-container {
            float: left;
            height:54px;
            width: 54px;
            margin-left: -16px;
            // background: transparentize($touche1, 0.95);
            border-radius: 50%;
            text-align: center;
            margin-right: -2px;
            transition: transform 0.15s;
            @include brk($m) { float: left; }
            .icon {
                margin-top: 13px;
                width:28px;
                height:28px;
                transition: transform 5s;
                .st0{fill:#00908E;}
                .st1{fill:#046361;}
            }
        }
        &:hover {
            // p {color:$touche2}
            .icon-container {transform: scale(0.98)}
            .icon {transform: scale(1.2)}
            path {
                transition: fill 0.2s;
                fill:$touche2
            }
        }
    }
    .plan-acces {
        border: 1px solid $touche2;
        margin-top: 10px;
        line-height:1;
        p {
            font-family: $family2;
            padding: 17px 27px 17px;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-weight: $bold;
            font-size: 12px;
            line-height:1;
        }
        &:hover {
            background:$touche1;
            border: 1px solid $touche1;
            p {
                color:#fff;
            }
        }

    }
}
















.page.contact .col-main {
	.format {
		padding: 0px 0 50px 12%;
	    display: inline-block;
	    width: 100%;
	    @include brk($t) {
	        padding: 0 9% 55px;
	    }
	    @include brk($m) {
	        padding: 0 6%;
	        margin-top: 40px;
	        margin-bottom:40px;
	    }
	}
}














.page.region {
    background:#fff;
}



// PAGE BANNER TOP

$page_wrapper-bannerTop-desktop: 450px;
$page_wrapper-bannerTop-laptop: 420px;
$page_wrapper-bannerTop-tablet: 340px;
$page_wrapper-bannerTop-mobile: 240px;



.page.region .banner.top {
    background:$footer-color;
    transition: height 1s;
    height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { height:$page_wrapper-bannerTop-mobile; }
}


.page.region .banner.top {
    position: relative;
    .image {
        background-position: 50% 50%;
        // filter: saturate(0%);
        // &::after {
        //     @extend .overlay;
        //     content:"";
        //     background: transparentize(#212121, 0.3);
        // }
    }
}






.page.region {
    .banner.medium {
        background: #ffffff;
        min-height: 400px;
        padding:70px 0 70px;
        @include brk($t) {
            padding:50px 0 50px;
        }
        >.banner-content {
            // padding:0;
            // max-width: none;
        }
        .col-right {
            width:calc(75%);
            @include brk($td) {
                width:100%;
            }
        }
        .col-left {
            width:25%;
            @include brk($td) {
                width:100%;
            }
        }
    }
}





.page.region .banner.medium .col-right {


    .top-bloc {
        margin-bottom: 85px;
        @include brk ($m) {
            margin-bottom: 55px;
        }
        .titre {
            font-size: 32.5px;
            font-weight: $bold;
            color: #496363;
            text-transform: uppercase;
            margin-bottom: 20px;
            @include brk ($td) {
                font-size: 30px;
                margin-bottom: 15px;
            }
            @include brk ($m) {
                font-size: 24px;
                margin-bottom: 15px;
            }
        }
        .chapeau {
            p {
                font-size: 20px;
                font-weight: $light;
                font-style: italic;
                color: #00908e;
                @include brk ($td) {
                    font-size: 19px;
                }
                @include brk ($m) {
                    font-size: 18px;
                }
            }
        }
    }






    .flex-image_texte {
        margin-bottom: 100px;
        padding:0;
        background: none;
        .banner-content {
            padding:0;
        }
        .col-image {
            height:322px;
            width:346px;
            overflow: hidden;
            margin-top: 2px;
            border-radius:none !important;
            overflow: visible !important;
            box-shadow: none!important;
            &.contains-img {
                @include brk ($m) {
                    height:auto;
                }
            }
            .image {
                // border-radius:6px;
                animation: fade 2s;
            }
            >img {
                width:100%;
                height:auto;
                border-radius:8px;
                @include brk ($tp) {
                    width:auto;
                    height:100%;
                }
                @include brk ($m) {
                    width:100%;
                    height:auto;
                }
            }
            .image-lightbox {
                img {
                    width:100%;
                    visibility: hidden;
                    height:auto;
                }
            }
            .bandeau {
                background:$touche1;
                color:#fff;
                z-index: 10;
                position: absolute;
                top:86px;
                right:calc(-75% + 100px);
                width:150%;
                text-align: center;
                padding: 14px 0;
                font-size: 10px;
                text-transform: uppercase;
                font-weight: $bold;
                letter-spacing: 3px;
                transform: rotate(45deg);
                @include brk ($m) {
                    top:75px;
                }
            }
        }
        .col-texte {
            width:calc(100% - 346px);
            padding-left:65px;
            @include brk ($t) {
                padding-left:55px;
            }
            @include brk ($tp) {
                padding-left:0px;
            }
            .sur-titre {
                margin-bottom: 20px;
                &::before {
                    content:"";
                    height:1px;
                    width:54px;
                    margin-right: 20px;
                    margin-left: -2px;
                    opacity: 1;
                    transition: width 0.1s, opacity 0.5s;
                    display: inline-block;
                    transform: translateY(-4px);
                    background: transparentize($touche1, 0);
                    @include brk($t) {
                        display:none;
                    }
                }
            }
            .titre {
                font-size: 27.5px;
                font-weight: $bold;
                color: #496363;
                text-transform: uppercase;
                margin-bottom: 17px;
                 @include brk ($m) {
                    font-size: 24px;
                    margin-bottom: 15px;
                }
            }
            .chapeau {
                p {
                    font-size: 16.5px;
                    font-weight: $bold;
                    color: #496363;
                }
                margin-bottom: 23px;
            }
            .texte {
                p {
                    font-size: 16.5px;
                    font-weight: $light;
                    color: #496363;
                }
            }
            .btn { 
                background:$touche1;
                border-radius:30px;
                margin-top: 20px;
                height: 40px;
                position: relative;
                text-decoration: none!important;
                &:hover {
                    background: #80bb22;
                }
                p {
                    padding:13px 77px 0px 26px;
                    font-size: 14px;
                    font-weight: $bold;
                    letter-spacing: 0;
                    color:$white;
                    text-shadow: 1px 1px 0px rgba(95, 107, 120, 0.24);
                }
                .icon {
                    font-size: 38px;
                    color:#fff;
                    width:47px;
                    text-align: center;
                    font-weight: $normal;
                    height:100%;
                    position: absolute;
                    top:3px;
                    line-height: 1;
                    right:6px;
                    border:none;
                    &::before {
                        content:"";
                        height:25px;
                        top:4px;
                        width:1px;
                        background:transparentize(#fff, 0.8);
                        position: absolute;
                        display: inline-block;
                        left:0px;
                    }
                    span {
                        transition: transform 0.5s;
                        display: inline-block;
                        height:100%;
                        line-height: 1;
                        color:$white;
                        transform: translateY(-5px);
                        text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.34);
                    }
                }
            }
        }
        @include brk ($t) {
            margin-bottom:70px;
        }
        @include brk($tp) {
            .col-image, .col-texte {
                width:100%;
                float: none!important;
            }
            .col-image {
                height:275px;
                margin-bottom:40px;
                .image-lightbox img {
                    margin: 0 auto;
                    display: block;
                    width:60%; 
                }
            }
        }
        @include brk ($m) { 
            margin-bottom:55px;
            .col-image {
                height:200px;
                margin-bottom:40px;
                .image {
                    width:130%;
                    left:-15%;
                }
            }
            ul.caracteristiques li {
                padding: 12px 0 !important;
                &:last-child {
                    border-bottom:none;
                }
            }
        }
    }

    .flex-image_texte {
        .col-texte { float: right; }
    }

}




.page.lieu {
    background:#fff;
}



// PAGE BANNER TOP

$page_wrapper-bannerTop-desktop: 593px;
$page_wrapper-bannerTop-laptop: 580px;
$page_wrapper-bannerTop-tablet: 650px;
$page_wrapper-bannerTop-mobile: 393px;



.page.lieu .banner.top {
    background:#f8f9f4;
    transition: height 1s;
    height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { height:auto; }
}
.page.lieu .banner.top .bloc-container {
    transition: line-height 1.8s;
    line-height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { line-height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { line-height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { line-height:1; }
}





.page.lieu .banner.top {
    position: relative;
    .image {
        background-position: 50% 50%;
        width: calc(50% + 100px);
        right:0;
        left:auto;
        animation: fade 1s;
        @include brk($t) { 
            width: calc(50%);
        }
        @include brk($tp) { 
            width:100%;
            position: relative;
            height:225px;
        }
        // filter: saturate(0%);
        // &::after {
        //     @extend .overlay;
        //     content:"";
        //     background: transparentize(#212121, 0.3);
        // }
    }
    .col-left {
        padding-top:80px;
        width: calc(50% - 150px);
        @include brk($t) { 
            width: calc(50% - 50px);
        }
        @include brk($tp) { 
            padding: 30px 0 40px;
            width: 100%;
        }
        .sur-titre {
            font-size: 11px;
            font-weight: $black;
            font-family: $family2;
            color:$touche1;
            animation: fade 2.5s;
            text-transform: uppercase;
            letter-spacing: 4px;
            position: relative;
            z-index: 4;
            user-select:none;
            margin-bottom: 45px;
            padding-left:30px;
            @include brk($t) { 
            }
            @include brk($tp) { 
                font-size: 9px; 
                margin-bottom: 12px;
            }
            .icon {
                width:16px;
                height:16px;
                position: absolute;
                top:2px;
                left:0px;
                .st0{fill:$touche1;}
            }
        }
        .titre {
            font-size:50px;
            font-weight: $light;
            font-family: $family1;
            color:#496363;
            animation: fade 2s;
            line-height: 1.1;
            letter-spacing: 0.5px;
            position: relative;
            text-transform: uppercase;
            z-index: 4;
            user-select:none;
            margin-bottom: 30px;
            @include brk($t) { font-size: 37.5px; }
            @include brk($m) { font-size: 32.5px; }

        }
        .texte {
            p {
                color:#727272;
                line-height: 1.8;
                margin-bottom: 10px;
            }
        }
        .btn-reservation {
            border-radius:30px;
            margin-top: 55px;
            border:1px solid $touche1;
            position: relative;
            &:hover {
                background: $touche1;
                p, .icon span {
                    color:#fff;
                }
                .icon::before {
                    background:transparentize(#fff, 0.8);
                }
            }
            p {
                padding:22px 150px 20px 30px;
                font-size: 12.5px;
                font-weight: $bold;
                letter-spacing: 2px;
                text-transform: uppercase;
                color:$touche1;
            }
            .icon {
                font-size: 38px;
                color:$touche1;
                width:47px;
                text-align: center;
                font-weight: $normal;
                height:100%;
                position: absolute;
                top:15px;
                line-height: 1;
                right:14px;
                border:none;
                &::before {
                    content:"";
                    height:37px;
                    top:-2px;
                    width:1px;
                    background:transparentize($touche1, 0.8);
                    position: absolute;
                    display: inline-block;
                    left:-5px;
                }
                span {
                    transition: transform 0.5s;
                    display: inline-block;
                    height:100%;
                    line-height: 1;
                    transform: translateY(-5px);
                    color:$touche1;
                }
            }
            @include brk($t) {
                // display: none;
            }
        }
    }
}






.page.lieu {
    .banner.medium {
        background: #ffffff;
        min-height: 400px;
        >.banner-content {
            padding:0;
            max-width: none;
        }
    }
}





.page.lieu .banner.galerie {
    padding-top: 100px;
    padding-bottom: 130px;
    .banner-content {
        // padding:0;
    }
}




// BANNER GALERIE -----------------------------------------------------


.page.lieu .banner.galerie, .page.type.galerie .banner.galerie {
    // background:#ffffff;
    .image-fond {
        opacity: 0.9;
        position: absolute;
        display: inline-block;
        left:0;
        right:0;
        bottom:0;
        width:100%;
        height:525px;
        background-position:50% 50%;
        background-size:cover;
    }
    .content {
        text-align: left;
    }
    .titre-section {
        font-size: 40px;
        font-weight: $light;
        font-family: $family1;
        color:#496363;
        text-transform: uppercase;
        margin-bottom: 35px;
        @include brk($m) {
            font-size: 30px;
            margin-bottom: 15px;
        }
    }
    .sous-titre {
        font-size: 15.5px;
        font-weight: $light;
        font-family: $family1;
        line-height: 1.5;
        margin-bottom: 50px;
        p {padding:0}
        @include brk($m) {
            font-size: 20px;
            margin-bottom: 35px;
        }
    }

    .justified {
        animation: fade 3s;
        #gallery {
            width:calc(100% + 10px);
            margin-left: -5px;
        }
        a {
            cursor: pointer;
            opacity: 0.9;
            transition: opacity 0.3s;
            animation-fill-mode: none !important;
            img {
                // transition: filter 0.3s;
                // filter:brightness(88%);
            }
            .caption {
                font-family: $family1;
                display: block;
                opacity: 1!important;
                background-color: transparentize(#000, 0.3);
                // display:none!important;
            }
            span.description {display:none;}
            &:hover {
                opacity: 1;
                // img{filter:brightness(100%) contrast(105%);}
            }
        }
    }
}







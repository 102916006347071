


#wpadminbar {
    background: transparentize(#23282d, 0.15)!important;
    transition: background 1s;
    &:hover {
        transition: background 0.6s;
        background: transparentize(#23282d, 0.05)!important;
    }
    .ab-sub-wrapper,
    #wp-admin-bar-dashboard,
    #wp-admin-bar-menus,
    #wp-admin-bar-themes,
    #wp-admin-bar-site-name-default,
    #wp-admin-bar-search {display:none}

    #wp-admin-bar-site-name a::before {
        top:3px!important;
        @include brk($m) {
            top:7px!important;
        }
    }
}
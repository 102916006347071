$ft: $t + 0px;
$fm: $m + 0px;



footer {
    overflow-y: hidden;
    background: $footer-color;
    margin-top: -5px;
    z-index: 10;
    #footer_top {
        background:#152023;
        padding:60px 0 30px;
        border-top: 1px solid transparentize(#000, 0.9);
        @include brk($ft) {
            padding:51px 0 30px;
        }
        @include brk($fm) {
            padding:40px 0 30px;
        }
    }
    #footer_bottom {
        padding: 8px 0 10px;
        @include brk($fm) {
             padding: 18px 0 13px;
            .banner-content { padding:0; }
        }
    }
    .banner-content {
        @include brk($ft) { padding: 0 6%; }
        @include brk($fm) { padding: 0 6%; }
    }
    .titre-section {
        color:#fff;
        font-size: 19px;
        font-weight: $bold;
        letter-spacing: 0.5px;
        margin-bottom: 15px;
        user-select:none;
        @include brk($fm) {
            // margin-bottom: 30px;
        }
    }
}




// LOGO ---------------------------------------

footer .logo-container {
    height:60px;
    width: 175px;
    float: left;
    @include brk($ft) {
        display: none;
    }
    .logo-image {
        height:100%;
        width:100%;
        background-size:contain;
        background-position:50% 50%;
    }
}







// COORDONNES -----------------------------------------

footer .bloc-coordonnees {
    width:30%;
    float: left;
    margin-left: 13%;
    @include brk($ft) { width: 48%; float: left;margin-left: 0%;}
    @include brk($fm) { width:100%; }
    h2, p, a {
        font-size: 13.5px;
        color:#fff;
        line-height: 2;
        font-weight: $light;
        font-family: $family1;
        user-select: text;
        @include brk($fm) { 
            font-size: 13.5px;
            letter-spacing: 0;
            // text-align: center;
        }
    }
    h2 {
        font-weight: $bold;
        @include brk($fm) { 
            text-align: center;
        }
    }
    span {
        display:block;
        color:#8a9c9c;
        font-size: 13.5px;
        a {padding-left:5px;}
        p {
            display: inline-block;
            @include brk($fm) { 
                display: block;
            }
        }
        @include brk($fm) { 
            text-align: center;
        }
        &[itemprop="address"]  {
            p {
                display: block;
            }
        }
    }
    a {position: relative;}
    a::after {
        content:"";
        display: inline-block;
        position: absolute;
        bottom:2px;
        left:4px;
        height:2px;
        width:0;
        opacity: 0;
        transition: opacity 0.5s, width 0.1s;
        background: lighten($touche2, 30%);
    }
    a:hover, &:focus {
        &::after {
            width:calc(100% - 4px);
            opacity: 1;
        }
    }
    span.tel {
        @include brk($fm) { 
            line-height: 2;
        }
    }
    .ville {
        margin-bottom: 20px;
    }
    .email {
        margin-top: 20px;
        margin-bottom: 25px;
    }
}










// COPYRIGHT LINE ----------------------------------

footer .copyright-line {
    display:inline-block;
    text-align:center;
    margin-left: -7px;
    width:100%;
    @include brk($fm) {
        width:100%;
        text-align: center;
        margin-left: 0px;
    }
    p, a, span {
        font-size: 11px;
        font-weight: $light;
        color:rgba(255,255,255,0.5);
        // text-transform: uppercase;
        letter-spacing:0px;
        transition: color 0.6s;
        @include brk($fm) {
            font-size: 10px;
        }
    }
    a {
        padding: 15px 9px;
        text-decoration: none;
        position: relative;
        cursor:pointer;
        @include brk($fm) {
            padding: 4px 5px;
        }
        &:hover, &:focus {color:#eee;}
        &:first-child {
            border-bottom:none;
            &:before {display:none;}
        }
        &:nth-last-child(1):before {
            @include brk($fm) {
                // display: none;
            }
        }
        &::before {
            content:"";
            display: inline-block;
            position: absolute;
            top:18px;
            left:-2px;
            width:1px;
            height:12px;
            background:rgba(255,255,255,0.15);
            @include brk($fm) {
                top:7px;
            }
            // @include brk($fm){display:none;}
        }
    }
    p {
        display:inline;
    }
}

















// NEWSLETTER ----------------------------------


footer .bloc-newsletter {
    float: right;
    margin-right: 0%;
    width: 37%;
    @include brk($ft) {
        width:48%;
        float: right;
        margin-right: 0px;
    }
    @include brk($fm) {
        width:100%;
        margin-top: 10px;
    }
    .titre-section {
        display: inline-block;
        float: left;
        width: 150px;
        @include brk($ft) {
            display: block;
            float: none;
            margin-bottom: 35px;
        }
        @include brk($fm) {
            width: 100%;
            text-align: center;
            margin-bottom: 22px;
            margin-top: 5px;
        }
    }
    form {
        display: inline-block;
        float: right;
        margin-top: -13px;
        position: relative;
        width:calc(100% - 150px);
        border: 1px solid transparentize(#fff, 0.8);
        height:65px;
        @include brk($ft) {
            display: block;
            float: none;
            width:100%;
        }
        @include brk ($fm) {
            float: none;
            width:100%;
            max-width: 420px;
            margin: 5px auto 0px;
        }
    }

    $newsletter_input_padding_left: 20px;

    input {
        height:100%;
        padding:0px 20px 0px 20px;
        padding-left: $newsletter_input_padding_left;
        width:calc(100% - 73px);
        color:rgba(255,255,255,1);
        float: left;
        font-weight: $normal;
        user-select: text;
        // border-radius:12px;
        font-size: 14px;
        letter-spacing: 1px;
        text-align: left;
        transition: border 0.5s;
        // border:1px solid rgba(255,255,255,0.2);
        &:focus {
            // border:1px solid rgba(255,255,255,0.3);
        }
        @include brk ($fm) {
        }
    }
    button {
        color:rgba(255,255,255,0.9);
        // border:1px solid rgba(255,255,255,0.2);
        // border-radius:50%;
        width:54px;
        height:calc(100% - 12px);
        margin-top: 6px;
        margin-right: 5px;
        float: right;
        font-size: 10px;
        font-weight: $black;
        background: $touche3;
        transition: color 0.1s, border 0.3s;
        letter-spacing: 1.5px;
        &:hover {
            color:rgba(255,255,255,1);
            background: darken($touche1, 15%);
        }
        @include brk ($fm) {
        }
    }


    .response_box {
        float: left;
        clear: both;
        font-size: 12px;
        color:rgba(255,255,255,1);
        font-weight: $light;
        font-style: italic;
        margin-top:12px;
        width:calc(100% - 73px);
        text-align: left;
        padding-left: $newsletter_input_padding_left;
        @include brk($ft) {margin-top:8px;}
        &.success {color:green;}
        &.error {color:red;}
        display: none;
    }

        
    // FIX FOR CHROME AUTOFILL
    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px #2a2a2a inset;
        -webkit-text-fill-color: transparentize(#fff,0.2) !important;
        border-bottom:1px solid #2a2a2a;
        &:focus {border-bottom:1px solid #143467!important;}
    }




    /**************  PLACEHOLDER ADJUST   ***************/
    input::placeholder, textarea::placeholder {
        font-size: 16.5px;
        font-weight: $light;
        color: transparentize(#fff, 0.2) !important;
        text-align: left;
        opacity: 1;
        letter-spacing: 0;
        transition: opacity 0.3s;
        @include brk($ft){ font-size:14px; }
        @include brk($fm){ font-size:14px; }
    }
    input:focus, textarea:focus {
        &::placeholder { opacity: 0.25; }
    }

}

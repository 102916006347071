





nav.nav_side {
    margin-top: 7px;

    width:245px;



	@include brk($td) {
        margin-top: 0px;
		padding-top:20px;
		display: none!important;
    }
    @include brk($m) {
	}


	>ul>li>a, 
    >ul>li:not(.current-menu-ancestor)>ul>li, 
    >ul>li:not(.current-menu-ancestor)>ul {
        display: none;
    }


    // LEVEL 2 -----------------------------------------

    >ul>li {

    	>ul>li {
    		display: inline-block;
            position: relative;
            padding: 5px 0 4px; 
            // border-top:1px solid transparentize(#000, 0.9);
    		&:first-child {border-top:none;}
    		@include brk($t) {
    			width: 100%;
    		}
    	}

    	>ul>li>a {
    		font-size:18.5px;
            font-weight: $bold;
            font-family: $family1;
            color: $touche2;
            display: inline-block;
            padding:9px 0;
            letter-spacing: 0.5px;
            line-height: 1;
            width:203px;
    		position: relative;
            // text-transform: uppercase;
            &:hover {
                color:$touche1;
            }
    		@include brk($t) {
    			width: 100%;
    		}
    	}
    	>ul>li.current-menu-item>a,
        >ul>li.current-menu-parent>a {
            font-weight: $bold;
            color:$touche1;
            &::before {
                content:"";
                position: absolute;
                background: $touche1;
                top:0px;
                left:-20px;
                height:100%;
                width:4px;
                text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.1);
            }
    	}
    }



    // LEVEL 3 -------------------------------------

    >ul>li>ul>li {

        >ul {
            margin-top: 8px;
        }

        >ul>li {
            display: inline-block;
            padding-left:40px;
            // border-top:1px solid transparentize(#000, 0.9);
            &:first-child {border-top:none;}
            @include brk($t) {
                width: 100%;
            }
        }

        >ul>li>a {
            font-size:15px;
            font-weight: $normal;
            display: inline-block;
            padding:4px 0;
            letter-spacing: 0.1px;
            line-height: 1.6;
            width:203px;
            color: #9a9a9a;
            position: relative;
            &:hover {
                color:$touche1;
            }
            &::before {
                content:"›";
                position: absolute;
                font-weight: $normal;
                // color: $touche1;
                top:4px;
                left:-10px;
            }
            @include brk($t) {
                width: 100%;
            }
        }
        >ul>li.current-menu-item a {
            color:$touche1;
        }
    }
}



.btn-01 {
    background: $touche1;
    padding:0!important;
    margin-left:0!important;
    &:hover, &:focus {
        background: darken($touche1, 15%);
        .icon span {transform:translate3d(1px,0,0);}
    }
    p {
        color:#fff;
        font-weight: $bold;
        font-size: 16.5px;
        padding:18px 90px 16px 30px;
        letter-spacing: 1.5px;
        display: inline-block;
        user-select:none;
        line-height: 1.65;
        text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.34);
    }
    .icon {
        font-size: 30px;
        color:#fff;
        width:52px;
        text-align: center;
        font-weight: $normal;
        height:34px;
        position: absolute;
        top:13px;
        line-height: 1;
        right:6px;
        border-left:1px solid rgba(255,255,255,0.3);
        span {
            transition: transform 0.5s;
            display: inline-block;
            text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.34);
        }
    }
    &::after {
        display: none!important;
    }
}








.vers-archive {
    font-size: 21px;
    font-weight: $bold;
    color:$touche2;
    position: absolute;
    right:0;
    top:20px;
    padding-left:70px;
    @include brk($td) {
        right:0;
    }
    @include brk($m) {
        top:auto;
        position: relative;
        right:auto;
        padding-left:0;
        font-size: 14px;
    }
    &:before {
        content:"";
        position: absolute;
        top:16px;
        left:-30px;
        display: inline-block;
        width:74px;
        height:1px;
        transition: left 0.3s;
        background:$touche2;
        @include brk($t) {
            display:none;
        }
    }
    &:hover {
        color:$orange;
        &:before {
            left:5px;
            background:$orange;
        }
    }
}










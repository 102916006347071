

.page-accueil {
    background: #fff;
}



// ACCUEIL HEIGHT BANNER TOP

$pgac_bT_h-desktop: 740px;
$pgac_bT_h-laptop: 650px;
$pgac_bT_h-tablet: 590px;
$pgac_bT_h-mobile: 347px;

.page-accueil .banner.top {
    background: $footer-color;
    height:$pgac_bT_h-desktop;
    transition: height 1s;
    @include brk ($l) { height:$pgac_bT_h-laptop; }
    @include brk ($t) { 
        height:auto; 
        background:#fff;
    }
    @include brk ($m) { height:auto; }
    .bloc-container {
        line-height: $pgac_bT_h-desktop;
        @include brk ($l) { line-height:$pgac_bT_h-laptop; }
        @include brk ($t) { line-height:1; }
        @include brk ($m) { line-height:$pgac_bT_h-mobile; }
    }
}






.page-accueil .banner.top {
    .banner-content {
        max-width: 1450px;  
        @include brk($t) {
            // background:#fff;
        }
    }
    .image {
        top:0;
        bottom:0;
        left:0;
        right:auto;
        width: calc(50% + 250px);
        background-position:50% 50%;
        @include brk($t) {
            width: calc(100%);
            height:590px;
            position: relative;
        }
        @include brk($m) {
            height:350px;
        }
    }
    .image-background {
        @extend .abs;
        left: auto;
        animation: fade 1s;
        width: calc(50% - 250px);
        background-size: cover;
        background-position:50% 100%;
        @include brk($t) {
            width: calc(100%);
        }
    }
    .bloc-container {
        display: none;
        .bloc {
            // width:40%;
            text-align: left;
            padding:80px 0% 0 calc(50% + 310px);
            @include brk($t) {
                padding:60px 10% 20px;
                text-align: center;
            }
            @include brk($m) {
                padding:30px 0% 0;
            }
            .textes {
                .sur-titre {
                    animation: fade 1.5s;
                    > p {
                        font-size: 11px;
                        font-weight: $bold;
                        font-family: $family2;
                        animation: fade 1s;
                        line-height: 2;
                        user-select:none;
                        text-transform: uppercase;
                        letter-spacing: 3.5px;
                        color:$touche1;
                        margin-bottom:20px;
                        transform: translate3d(0,0,0); 
                        &.move_up { animation: move_up_15 1s; }
                        @include brk($t) {
                            padding: 0 0;
                        }
                        @include brk($m) {
                            font-size: 10.5px;
                            // display: none;
                        }
                    }
                }
                .titre {
                    animation: fade 1.5s;
                    > h2 {
                        font-size: 60px;
                        font-weight: $light;
                        font-family: $family1;
                        clear: both;
                        color: transparentize(#496363, 0);
                        display: inline-block;
                        letter-spacing: 0.3px;
                        text-transform: uppercase;
                        word-spacing: 2px;
                        margin-bottom: 40px;
                        position: relative;
                        line-height: 1;
                        user-select:none;
                        transform: translate3d(0,0,0); 
                        b, strong, span {
                            font-weight: $bold;
                        }
                        &.move_up { animation: move_up_10 1s; }
                        @include brk($t) {
                            font-size: 50px;
                            margin-bottom: 25px;
                        }
                        @include brk($m) {
                            font-size: 35px;
                            line-height: 1.2;
                            margin-bottom: 15px;
                        }

                       
                        // TRAIT ------------------------
                        position: relative;
                        &::after {
                            content:"";
                            position: absolute;
                            top:34px;
                            left:-100px;
                            height:1px;
                            width:70px;
                            opacity: 1;
                            transition: width 0.1s, opacity 0.5s;
                            display: inline-block;
                            background: transparentize($touche1, 0.7);
                            @include brk($t) {
                                display:none;
                            }
                        }
                    }
                }
                .chapeau, .sous-titre {
                    animation: fade 2s;
                    p {                    
                        font-size: 15.5px;
                        font-weight: $light;
                        font-family: $family1;
                        color:#496363;
                        line-height: 1.65;
                        user-select:none;
                        transform: translate3d(0,0,0); 
                        &.move_up { animation: move_up_5 1s; }
                        @include brk($t) {
                        }
                        @include brk($m) {
                        }
                    }
                }
                // .lien {
                //     margin-top: 40px;
                //     height:60px;
                //     background: $touche2;
                //     animation: fade 2.5s;
                //     transform: translate3d(0,0,0); 
                //     &.move_up { animation: move_up_5 1s; }
                //     @include brk($m) {
                //         margin-top: 20px;
                //     }
                //     p {
                //         font-size: 17px;
                //         font-weight: $bold;
                //         font-family: $family1;
                //         color:$touche1;
                //         padding-top:18px;
                //         text-transform: uppercase;
                //     }
                //     &::after {
                //         content:"";
                //         position: absolute;
                //         bottom:-6px;
                //         left:0px;
                //         height:3px;
                //         width:0;
                //         opacity: 0;
                //         transition: width 0.1s, opacity 0.5s;
                //         display: inline-block;
                //         background: #fff;
                //         @include brk($td) {
                //             width:100%;
                //             opacity: 1;
                //         }
                //     }
                //     &:hover {
                //         // background:#003359;
                //         &::after {
                //             opacity: 1;
                //             width:100%;
                //         }
                //     }
                // }
                .lien {
                    border-radius:30px;
                    margin-top: 70px;
                    border:1px solid $touche1;
                    position: relative;
                    background:transparentize(#fff, 0.5);
                    transform: translate3d(0,0,0); 
                    &.move_up { animation: move_up_5 1s; }
                    &:hover {
                        background: $touche1;
                        p, .icon span {
                            color:#fff;
                        }
                        .icon::before {
                            background:transparentize(#fff, 0.8);
                        }
                    }
                    p {
                        padding:22px 100px 20px 30px;
                        font-size: 12.5px;
                        font-weight: $bold;
                        letter-spacing: 2px;
                        text-transform: uppercase;
                        color:$touche1;
                    }
                    .icon {
                        font-size: 38px;
                        color:$touche1;
                        width:47px;
                        text-align: center;
                        font-weight: $normal;
                        height:100%;
                        position: absolute;
                        top:15px;
                        line-height: 1;
                        right:14px;
                        border:none;
                        &::before {
                            content:"";
                            height:37px;
                            top:-2px;
                            width:1px;
                            background:transparentize($touche1, 0.8);
                            position: absolute;
                            display: inline-block;
                            left:-5px;
                        }
                        span {
                            transition: transform 0.5s;
                            display: inline-block;
                            height:100%;
                            line-height: 1;
                            transform: translateY(-5px);
                            color:$touche1;
                        }
                    }
                    @include brk($t) {
                        margin-top: 40px;
                        margin-bottom: 30px;
                        // display: none;
                    }
                }
            }
        }
    }
}





// SLIDER ------------------------------------------

.page-accueil #slider_home_top {
    height: 100%;
    
}



// SLIDER CONTROL PREV / NEXT  ----------------------------------

.slider-control-prev-next {
    height:100px;
    width:200px;
    position: absolute;
    left:calc(50% + 50px);
    bottom:0;
    z-index: 10;
    animation: fade 2s;
    @include brk($t) {
        top:490px;
        left:auto;
        right:-20px;
        // transform: rotate(90deg);
    }
    @include brk($m) {
        display: none;
    }
    .inside {
        .control {
            width:100px;
            height:100px;
            line-height: 100px;
            display: inline-block;
            text-align: center;
            &:hover svg {transform: scale(0.5)!important}
            .icon {
                @extend .bloc;
                height:40px;
                width:40px;
                svg {
                    transition: transform 0.3s;
                    transform: scale(0.42);
                    polygon { fill:#fff; }
                }
            }
            &.prev {
                float:left; 
                padding-left:35px;
                .icon {transform: rotate(180deg)}
            }
            &.next {
                float:right;
                padding-right:35px;
            }
        }
    }
}

















.banner.les-lieux, .banner.activites {
    .sur-titre {
        font-size: 11px;
        font-weight: $bold;
        font-family: $family2;
        text-transform: uppercase;
        letter-spacing: 3.5px;
        color:$touche1;
        margin-bottom:20px;
        @include brk($td) {
            font-size: 10px;
        }
    }
    .titre {
        color: #496363;
        font-size: 35px;
        font-weight: $light;
        line-height: 1.2;
        padding-bottom:15px;
        position: relative;
        text-transform: uppercase;
        span {
            font-weight: $bold;
        }
        &::after {
            content:"";
            position: absolute;
            top:22px;
            left:-83px;
            height:1px;
            width:54px;
            opacity: 1;
            transition: width 0.1s, opacity 0.5s;
            display: inline-block;
            background: transparentize($touche1, 0.7);
            @include brk($t) {
                display:none;
            }
        }
        @include brk($m) {
            font-size: 27.5px;
        }
    }
}




















.banner.les-lieux {
    margin-top: -5px;
    min-height: 550px;
    padding:70px 0 70px;
    @include brk($t) {
        padding:0px 0 40px;
    }
    @include brk($m) {
        padding:0px 0 40px;
    }
    .col {
        @include brk($t) {
            width:100%!important;
        }
    }
    .col1 {
        width:calc(50%);
        display: inline-block;
        @include brk($t) {
            width:100%;
            min-height: 400px;
            float: none;
            padding-top:60px;
            padding-bottom:55px;
        }
        @include brk($m) {
            padding-top:45px;
            padding-bottom:25px;
        }
        .sous-titre {
            position: relative;
            &:before {
                content:"";
                display: inline-block;
                position: absolute;
                width:70px;
                height:1px;
                top:9px;
                left:-110px;
                background:$touche1;
                @include brk($l) {
                    display:none;
                }
            }
        }
        .texte {
            p {
                color:#848484;
                @include brk($m) {
                    line-height: 1.65;
                }
            }
        }
        .lien {
            font-size: 17px;
            font-weight: $bold;
            color:#2195a5;
            position: relative;
            display: inline-block;
            float: left;
            clear: both;
            padding:4px 0;
            @include brk($t) {
                padding-left:15px;
            }
            &:before {
                content:"›";
                position: absolute;
                top:4px;
                left:-20px;
                // font-size: 64px;
                display: inline-block;
                font-weight: $normal;
                color:#2195a5;
                @include brk($t) {
                    top:2px;
                    left:0px;
                }
            }
            &:hover {
                color:$touche3;
            }
        }
        .btn-video {
            border-radius:30px;
            margin-top: 70px;
            border:1px solid $touche1;
            position: absolute;
            right:-120px;
            bottom:5px;
            @include brk($t) {
                right:0;
            }
            @include brk($m) {
                position: relative;
                right:auto;
                bottom:auto;
                margin-top: 40px;
                margin-bottom: 0px;
            }
            &:hover {
                background: $touche1;
                p, .icon span {
                    color:#fff;
                }
                .icon-triangle {
                    path {
                        fill:#fff;
                    }
                }
            }
            p {
                padding:22px 55px 20px 80px;
                font-size: 12.5px;
                font-weight: $bold;
                letter-spacing: 2.5px;
                text-transform: uppercase;
                color:$touche1;
            }
            .icon-triangle {
                height:14px;
                width:14px;
                position: absolute;
                top:24px;
                left:38px;
                path {
                    fill:#2fbd7f;
                }
            }
            @include brk($t) {
                // display: none;
            }
        }
    }
    .image-background {
        @extend .abs;
        height:920px;
        left: auto;
        animation: fade 1s;
        width: calc(50% - 250px);
        background-size: cover;
        background-position:100% 100%;
        @include brk($t) {
            // height:500px;
        }
        @include brk($t) {
            top:0px;
            height:100%;
            width: 114%;
            left:-7%;
            background-position:85% 0%;
        }
        @include brk($m) {
            background-position:65% 0%;
        }
    }
    .col2 {
        float: right;
        position: relative;
        width:calc(50% - 310px);
        padding-top:45px;
        @include brk($t) {
            width:100%;
            float: none;
            padding-bottom:55px;
        }
        @include brk($m) {
            padding-top:45px;
            padding-bottom:55px;
        }
        .titre {
            color:#fff;
            text-transform: none;
            margin-bottom: 20px;
            @include brk($td) {
                text-align: center;
            }
        }
        ul {
            margin-bottom: 40px;
            width:120%;
            @include brk($td) {
                width:100%;
                display: inline-block;
            }
            li {
                padding-left: 30px;
                @include brk($td) {
                    width:50%;
                    display: inline-block;
                    float: right;
                    padding-left: 0px;
                }
                @include brk($td) {
                    width:100%;
                    float: none;
                }
                a {
                    color:#2fbd7f;
                    display: inline-block;
                    width:100%;
                    text-transform: uppercase;
                    font-size: 13px;
                    letter-spacing: 4px;
                    padding: 19px 0;
                    transition: color 0.3s;
                    border-bottom:1px solid transparentize(#fff, 0.9);
                    position: relative;
                    @include brk($m) {
                        font-size: 12px;
                        letter-spacing: 3px;
                        line-height: 1.5;
                    }
                    &::before {
                        content:"";
                        width:10px;
                        height:1px;
                        background:transparentize(#2fbd7f, 0.5);
                        position: absolute;
                        top:29px;
                        left:-29px;
                        @include brk($td) {
                            display: none;
                        }
                    }
                    &::after {
                        content:"›";
                        position: absolute;
                        top:7px;
                        right:0;
                        font-size: 25px;
                        @include brk($m) {
                            top:11px;
                            font-size: 21px;
                        }
                    }
                }
                &:hover {
                    a {
                        color:#fff;
                    }
                }
                &:last-child {
                    a {
                        border-bottom:none;
                    }
                }
            }
        }
        .une-date {
            position: relative;
            z-index: 80;
            @include brk($td) {
                width:100%;
                display: inline-block;
            }
            p, a {
                color:#fff;
                font-weight: $light;
            }
            a {
                font-style: italic;
                -webkit-text-stroke: #fff;
                text-decoration: underline;
            }
        }
    }
}






























.banner.activites {
    margin-top: -100px;
    padding:70px 0 90px;
    @include brk($t) {
        background:#f0f5f3;
        margin-top: 0px;
    }
    
    @include brk($m) {
        padding:30px 0 0px;
    }
    .banner-content {
        @include brk($m) {
            padding:0
        }
    }
    .content>.titre {
        margin-bottom: 20px;
        @include brk($t) {
            padding-left:6%;
        }
    }
    .sous-titre {
        margin-bottom: 55px;
        @include brk($t) {
            padding:0 6%;
        }
        @include brk($tp) {
            display:none;
        }
    }
    
    #slider_activites, ul.slides {
        height:580px;
        @include brk($td) {
            height:785px;
        }
        @include brk($m) {
            height:775px;
        }
    }
    #slider_activites .image {
        @include brk($td) {
            height:345px;
        }
        @include brk($m) {
            height:170px;
        }
    }
    #slider_activites .text-container {
        background:#00908e;
        width:50%;
        height:470px;
        position: absolute;
        right:0;
        bottom:0;
        padding:45px 65px;
        @extend .cache-right;
        @extend .cache-right-bottom;
        @include brk($td) {
            width:100%;
            height:440px;
            padding:45px 50px;
        }
        @include brk($tp) {
            // width:100%;
            // padding:35px 30px 20px;
            // height:auto;
        }
        @include brk($m) {
            height:605px;
            padding:35px 6% 20px;
        }
        &::before, &::after {
            background: #e0e2d8;
             @include brk($tp) {display:none}
        }
        .date {
            font-size: 11px;
            font-weight: $black;
            font-family: $family2;
            text-transform: uppercase;
            letter-spacing: 3px;
            color:#032e2d;
            margin-bottom:20px;
        }
        .titre {
            color:#fff;
            font-size: 27.5px;
            line-height: 1.4;
            margin-bottom: 10px;
            text-transform: none;
            font-weight: $bold;
            @include brk($tp) {
                font-size: 27.5px;
            }
        }
        .texte {
            p, a {
                color:#fff;
                font-size: 14px;
                font-weight: $light;
                line-height: 1.6;
                font-family: $family2;
                -webkit-text-stroke: #fff;
                @include brk($td) {
                    // display: none;
                }
            }
            p {
                margin-bottom: 20px;
            }
            a {
                font-weight: $bold;
                font-size: 15.5px;
                text-decoration: underline;
            }
        }
        &:hover {
            .lire-la-suite:after {
                width:100%;
                opacity: 1;
            }
        }
    }

    // SLIDER CONTROL PREV / NEXT  ----------------------------------

    .slider-control-prev-next {
        height:100px;
        width:200px;
        position: absolute;
        left:auto;
        right:-20px;
        bottom:0;
        z-index: 10;
        animation: fade 2s;
        @include brk($t) {
            top:auto;
            // left:calc(40% - 100px);
        }
        @include brk($tp) {
            // display: none;
        }
        .inside {
            .control {
                width:100px;
                height:100px;
                line-height: 100px;
                display: inline-block;
                text-align: center;
                &:hover svg {transform: scale(0.5)!important}
                .icon {
                    @extend .bloc;
                    height:40px;
                    width:40px;
                    svg {
                        transition: transform 0.3s;
                        transform: scale(0.42);
                        polygon { fill:#fff; }
                    }
                }
                &.prev {
                    float:left; 
                    padding-left:35px;
                    .icon {transform: rotate(180deg)}
                }
                &.next {
                    float:right;
                    padding-right:35px;
                }
            }
        }
    }


}





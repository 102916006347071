.page.equipe {
    background: darken(#ffffff, 5%);
}




.page.equipe {
    .banner.top {
        .titre {
            @include brk($sm) {
                text-align: center;
            }
        }
    }
}




.page.equipe {
    .banner.medium {
        min-height: 600px;
        padding:85px 0 50px;
        @include brk($t) {
            padding:65px 0 40px;
        }
        @include brk($sm) {
            padding:50px 0 30px;
        }
        >.banner-content {
            // padding:0;
            // max-width: none;
        }

    }
}




 .page.equipe .banner.medium {
    ul {
        width:calc(100% + 20px);
        @include brk($t) {
            width:calc(100% + 50px)
        }
        @include brk($td) {
            width:calc(100% + 20px)
        }
    }
    li {
        margin-bottom: 30px;
        list-style:none;
        display: inline-block;
        transform: translate3d(0,0,0);
        width:50%;
        height:225px; 
        cursor: pointer;
        float: left;
        padding-right:20px;
        @include brk($t) {
            width:33.33%;
            height:400px;
            padding-right:50px;
            margin-bottom: 40px;
        }
        @include brk($td) {
            width:100%;
            height:225px; 
            padding-right:20px;
            margin-bottom: 30px;
        }
        @include brk($sm) {
            width:260px;
            height:400px;
            float: none;
            display: block;
            margin: 0 auto 30px;
        }
        .inside {
            background:#fbfafa;
            transition: box-shadow 0.2s;
            box-shadow: 0px 5px 39px 0px rgba(0, 0, 0, 0.08);
            @include brk($td) {
                background:#fff;
            }
        }
        .image-container {
            width:218px;
            height:100%;
            display: inline-block;
            transition: filter 0.5s;
            filter: brightness(95%);
            @include brk($t) {
                width:100%;
                height:200px;
            }
            @include brk($td) {
                filter: brightness(100%);
                width:218px;
                height:100%;
            }
            @include brk($sm) { 
                width:100%;
                height:200px; 
            }
            .image {
                background-position: 50% 45%;
            }
            .cache-losange {
                position: absolute;
                width:calc(100% + 4px);
                height:calc(100% + 4px);
                left:-2px;
                top:-2px;
                .st0{fill:#FFFFFF;}
                .st1{fill:none;stroke:#FFFFFF;stroke-width:2;stroke-miterlimit:10;}
            }
        }
        .textes-container {
            float: right;
            position: relative;
            height:100%;
            width:calc(100% - 218px);
            padding:30px 40px;
            @include brk($t) {
                width:100%;
                height:200px;
                padding:25px 30px;
            }
            @include brk($td) {
                height:100%;
                width:calc(100% - 218px);
                padding:30px 40px;
            }
            @include brk($sm) {
                width:100%;
                height:200px;
                padding:25px 30px;
            }
            .nom {
                font-size: 15px;
                font-weight: $bold;
                color:$touche2;
                line-height: 1.15;
                text-transform: uppercase;
                letter-spacing: 1.5px;
                cursor: pointer;
                margin-bottom: 7px;
                @include brk($t) {
                    // font-size: 24px;
                }
                @include brk($m) {
                    // font-size: 22px;
                }
            }
            .fonction {
                font-size: 13px;
                font-weight: $normal;
                color:$touche1;
                cursor: pointer;
                padding-bottom:16px;
                margin-bottom: 7px;
                border-bottom:1px solid transparentize($touche1, 0.5);
                @include brk($t) {
                }
                @include brk($m) {
                    font-size: 13px;
                }
            }
            a {
                font-size: 13px;
                font-weight: $light;
                position: relative;
                display: block;
                padding:0;
                margin:0;
                text-align: left;
                &::after {display:none;}
                &:not(.phone):hover  {
                    p { 
                        color:$touche1;
                        .underline {width: 100%;opacity:0.8}
                    }
                    .icon path {fill:$touche1;}
                }
                .icon {
                    width:30px;
                    height:30px;
                    position: absolute;
                    top:9px;
                    left:1px;
                    path {fill:#b1b1b1;transition: fill 1s;}
                }
                p {
                    text-align: left;
                    display: inline-block;
                    transition: color 0.2s;
                    padding:10px 0px 10px 0px;
                    position: relative;
                    // .underline {display:none}
                    .underline {
                        // content:"";
                        display: inline-block;
                        position: absolute;
                        width: 0%;
                        left:0px;
                        bottom:8px;
                        height:1px;
                        opacity: 0;
                        transition: width 0.1s, opacity 0.5s;
                        // background: $touche1;
                    }
                }

            }
            .lire-la-suite {
                color:$touche1;
                font-size:14px;
                font-weight: $bold;
                position: absolute;
                bottom:25px;
                left:40px;
                opacity: 0.8;
                cursor:pointer;
                transition: color 0.1s, opacity 1s;
                @include brk($t) {
                    left:30px;
                }
                @include brk($td) {
                    opacity: 1;
                    left:40px;
                }
                @include brk($sm) {
                    opacity: 1;
                    left:30px;
                }
            }
        }
        &:hover {
            .image-container {
                filter: brightness(100%);
            }
            .inside {
                background:#fff;
                box-shadow: 0px 5px 39px 0px rgba(0, 0, 0, 0.12);
            }
            .lire-la-suite {
                opacity: 1 !important;
            }
        }
    }
}




.page.type {
    background:#fff;
}



// PAGE BANNER TOP

$page_wrapper-bannerTop-desktop: 450px;
$page_wrapper-bannerTop-laptop: 420px;
$page_wrapper-bannerTop-tablet: 393px;
$page_wrapper-bannerTop-mobile: 393px;



.page.type .banner.top {
    background:$footer-color;
    transition: height 1s;
    height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { height:$page_wrapper-bannerTop-mobile; }
}
.page.type .banner.top .bloc-container {
    transition: line-height 1.8s;
    line-height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { line-height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { line-height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { line-height:$page_wrapper-bannerTop-mobile; }
}





.page.type .banner.top {
    position: relative;
    .image {
        background-position: 50% 50%;
        // filter: saturate(0%);
        // &::after {
        //     @extend .overlay;
        //     content:"";
        //     background: transparentize(#212121, 0.3);
        // }
    }
    .bloc {
        padding: 100px 0 0 0;
        @include brk($t) {
            padding: 50px 0 0 0;
            text-align: center;
        }
    }
    .sur-titre {
        font-size: 11px;
        font-weight: $bold;
        font-family: $family1;
        color:$touche1;
        animation: fade 2.5s;
        text-transform: uppercase;
        letter-spacing: 4px;
        position: relative;
        z-index: 4;
        user-select:none;
        margin-bottom: 18px;
        @include brk($t) { 
        }
        @include brk($m) { 
            font-size: 9px; 
            margin-bottom: 12px;
        }
    }
    .titre {
        font-size:50px;
        font-weight: $thin;
        font-family: $family1;
        color:#fff;
        animation: fade 2s;
        line-height: 1.1;
        letter-spacing: 0.5px;
        position: relative;
        text-transform: uppercase;
        z-index: 4;
        user-select:none;
        @include brk($t) { font-size: 55px; }
        @include brk($m) { font-size: 25px; }

        // TRAIT ------------------------
        &::after {
            content:"";
            position: absolute;
            top:22px;
            left:-83px;
            height:1px;
            width:54px;
            opacity: 1;
            transition: width 0.1s, opacity 0.5s;
            display: inline-block;
            background: transparentize(#fff, 0.6);
            @include brk($o) {
                display:none;
            }
        }
    }
    .icon-page {
        position: absolute;
        width: 180px;
        height: 180px;
        bottom:-111px;
        right:0;
        z-index: 20;
        animation: fade 1s;
        .icon {
            background-size:contain;
        }
    }
    
}






.page.type {
    .banner.medium {
        background: #ffffff;
        min-height: 400px;
        padding:90px 0 70px;
        @include brk($t) {
            padding:50px 0 50px;
        }
        >.banner-content {
            // padding:0;
            // max-width: none;
        }
        .col-right {
            width:calc(100% - 345px);
            @include brk($t) {
                width:100%;
            }
        }
        .col-left {
            width:345px;
            @include brk($t) {
                width:100%;
            }
        }
    }
}











// FIL D'ARIANE -----------------------------


.ariane-container {
    margin-top: -23px;
    margin-bottom: 35px;
    // background: #ffffff;
    @include brk($m) {
        margin-top: -20px;
        margin-bottom: 25px;
    }
    img {display:none;}
    #nav_ariane {
        animation: fade 2s;
        >ul>li:not(.current-menu-ancestor)>a, 
        >ul>li:not(.current-menu-ancestor)>ul>li, 
        >ul>li:not(.current-menu-ancestor)>ul,
        >ul>li>ul>li:not(.current-menu-item):not(.current-menu-parent):not(.current-menu-ancestor),
        >ul>li>ul>li>ul>li:not(.current-menu-item):not(.current-menu-ancestor)
        {
            display: none;
        }
        ul {
            display: inline-block;
        }
        ul>li a {
            font-size: 9.5px;
            font-weight: $bold;
            padding: 0px 0px;
            text-transform: uppercase;
            letter-spacing: 1.7px;
            line-height: 1;
            @include brk($m) {
                // display: block;
                padding: 0px 0px;
                font-size: 8px;
                letter-spacing: 1.5px;
            }
        }
        >ul>li>a {
            color:#000000;
            margin-right: 6px;
            &:before {
                content:"|";
                margin-right: 10px;
                display: inline-block;
            }
        }
        >ul>li.current-menu-ancestor:not(.current-menu-parent)>ul {
            display: block;
            padding-left:20px;
            >li>ul {
                padding-left:20px;
            }
        }
        >ul>li>ul>li.current-menu-parent>ul {
            width:100%;
        }

        ul>li>ul>li>a {
            color: $touche1;
        }
    }
}











